import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import RollerSkatingIcon from '@mui/icons-material/RollerSkating';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import { Link } from 'react-router-dom';
import { useUserAuth } from "../context/UserAuthContext";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        width: "60px",
        backgroundColor: "rgb(14, 14, 14)"
    },

    mainArea: {
        textAlign: "center",
        color: "#fff",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        border: "1px solid #1c1c1c",
        borderRadius: "10px",
        padding: "10px 6px 6px 6px",
        overflowY: "scroll"
    },

    iconMenu: {
        width: "11%",
        marginLeft: "1em"
    }
}));

const NavBarLeftVertical = ({ fights, sports }) => {
    const classes = useStyles();
    const { activeCategory, changeCategory } = useUserAuth();
    const currentCategory = activeCategory === null ? 1 : activeCategory;
    return (

        <div className={classes.mainArea}>
            {fights.length > 0 &&
                <Link to='/homeuser' >
                    <div className={classes.iconMenu} style={{ marginLeft: "0em", color: currentCategory === 1 ? "rgb(56, 161, 105)" : "#fff", }}
                      onClick={() => changeCategory(1)}>
                        <SportsMmaIcon style={{ fontSize: "35px" }} />
                    </div>
                </Link>}
            {sports.includes("Fútbol") &&
                <Link to='/sports-user' key={2} state={{ category: 'Fútbol' }} >
                    <div className={classes.iconMenu} style={{ color: currentCategory === 2 ? "rgb(56, 161, 105)" : "#fff" }}
                        onClick={() => changeCategory(2)}>
                        <SportsSoccerIcon style={{ fontSize: "35px" }} />

                    </div>
                </Link>}
            {sports.includes("Frontón") &&
                <Link to='/sports-user' key={3} state={{ category: 'Frontón' }} >
                    <div className={classes.iconMenu} style={{ color: currentCategory === 3 ? "rgb(56, 161, 105)" : "#fff" }}
                        onClick={() => changeCategory(3)}>
                        <SportsHandballIcon style={{ fontSize: "35px" }} />
                    </div>
                </Link>}
            {sports.includes("Carreras") &&
                <Link to='/sports-user' key={5} state={{ category: 'Carreras' }} >
                    <div className={classes.iconMenu} style={{ color: currentCategory === 5 ? "rgb(56, 161, 105)" : "#fff" }}
                        onClick={() => changeCategory(5)}>
                        <SportsMotorsportsIcon style={{ fontSize: "35px" }} />
                    </div>
                </Link>}
            {sports.includes("Patinaje") &&
                <Link to='/sports-user' key={6} state={{ category: 'Patinaje' }} >
                    <div className={classes.iconMenu} style={{ color: currentCategory === 6 ? "rgb(56, 161, 105)" : "#fff" }}
                        onClick={() => changeCategory(6)}>
                        <RollerSkatingIcon style={{ fontSize: "35px" }} />
                    </div>
                </Link>}
            {sports.includes("Tenis") &&
                <Link to='/sports-user' key={7} state={{ category: 'Tenis' }} >
                    <div className={classes.iconMenu} style={{ color: currentCategory === 7 ? "rgb(56, 161, 105)" : "#fff" }}
                        onClick={() => changeCategory(7)}>
                        <SportsTennisIcon style={{ fontSize: "35px" }} />
                    </div>
                </Link>}
            {sports.includes("Golf") &&
                <Link to='/sports-user' key={8} state={{ category: 'Golf' }} >
                    <div className={classes.iconMenu} style={{ color: currentCategory === 8 ? "rgb(56, 161, 105)" : "#fff" }}
                        onClick={() => changeCategory(8)}>
                        <GolfCourseIcon style={{ fontSize: "35px" }} />
                    </div>
                </Link>}
            {sports.includes("Voleibol") &&
                <Link to='/sports-user' key={9} state={{ category: 'Voleibol' }} >
                    <div className={classes.iconMenu} style={{ color: currentCategory === 9 ? "rgb(56, 161, 105)" : "#fff" }}
                        onClick={() => changeCategory(9)}>
                        <SportsVolleyballIcon style={{ fontSize: "35px" }} />
                    </div>
                </Link>}
            {sports.includes("Básquetbol") &&
                <Link to='/sports-user' key={10} state={{ category: 'Básquetbol' }} >
                    <div className={classes.iconMenu} style={{ color: currentCategory === 10 ? "rgb(56, 161, 105)" : "#fff" }}
                        onClick={() => changeCategory(10)}>
                        <SportsBasketballIcon style={{ fontSize: "35px" }} />
                    </div>
                </Link>}
            {sports.includes("Fútbol americano") &&
                <Link to='/sports-user' key={11} state={{ category: 'Fútbol americano"' }} >
                    <div className={classes.iconMenu} style={{ color: currentCategory === 11 ? "rgb(56, 161, 105)" : "#fff" }}
                        onClick={() => changeCategory(11)}>
                        <SportsFootballIcon style={{ fontSize: "35px" }} />
                    </div>
                </Link>}
        </div>
    );
}

export default NavBarLeftVertical;
