import styled from "styled-components";

const Card = styled.div`
  background-color: #141414;
  border-radius: 10px;
  padding: 1rem;
  transition: all 0.3s ease;
  height: 80px;
  border: 1px solid #1C1C1C;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const Content = styled.p`
  color: #EDF2F7;
  font-size: 16px;
  text-align: center;
  padding: 0;
  margin: 0;
`;

const CardInfo = ({ title, content, icon }) => {
  return (
    <Card>
      <Row>
        {icon}
        <Content>{title}</Content>
      </Row>
      <Content>{content}</Content>
    </Card>
  );
};

export default CardInfo;
