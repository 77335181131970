import React, { useEffect } from 'react';
import FightWeb from './Figth';
import FigthMobile from './FigthMobile';
import { useUserAuth } from '../../context/UserAuthContext';

const Fight = () => {
  const { isMobile } = useUserAuth();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      {isMobile ? <FigthMobile /> : <FightWeb />}
    </div>
  );
}

export default Fight;