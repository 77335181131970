import { useEffect, useState } from "react";
import { collection, query, where, orderBy, limit, onSnapshot, addDoc } from "firebase/firestore";
import { useParams } from 'react-router-dom';

import { firestore } from "../../firebase";
import ChatIframe from "../ui/ChatIframe";
import { useUserAuth } from "../../context/UserAuthContext";

const ChatIframeFigth = ({visible, toggleChat, active}) => {
  const paramsIdFight = useParams();
  const [messages, setMessages] = useState([]);
  const { user, userInfo } = useUserAuth();

  const getMessages = async () => {
    const msgCollectionRef = collection(firestore, "Messages");
    const q = query(msgCollectionRef, where("uidFight", "==", paramsIdFight.idFight), orderBy("sendDate"), limit(50));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const messagesItems = [];
    querySnapshot.forEach((doc) => {
        messagesItems.push(
          {
            ...doc.data(),
            id: doc.id,
            isUser: doc.data().uidUser === user.uid,
            isAdmin: doc.data().type === 'admin',
          }
        );
    });
    setMessages(messagesItems);
    });
    return () => unsubscribe();
  }

  const sendMessage = async (value) => {
    const msgRef = collection(firestore, "Messages");
    const newDate = new Date();
    const docData = {
      text: value,
      uidUser: user.uid,
      uidFight: paramsIdFight.idFight,
      sendDate: newDate,
      name: userInfo.userName,
      type: userInfo.type === 'admin' ? 'admin' : 'active',
    };
    await addDoc(msgRef, docData);
  }

  useEffect(() => {
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChatIframe messages={messages} visible={visible} handleSend={sendMessage} toggleChat={toggleChat} active={active} />
  );
};

export default ChatIframeFigth;
