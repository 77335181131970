import { useEffect, useState } from "react";
import { collection, query, where, orderBy, limit, onSnapshot, addDoc, doc, deleteDoc } from "firebase/firestore";
import { useParams } from 'react-router-dom';

import { firestore } from "../../firebase";
import Chat from "../ui/Chat";
import { useUserAuth } from "../../context/UserAuthContext";

const ChatEvent = ({ active }) => {
  const paramsIdEvent = useParams();
  const [messages, setMessages] = useState([]);
  const { user, userInfo } = useUserAuth();

  const getMessages = async () => {
    const msgCollectionRef = collection(firestore, "MessageSports");
    const q = query(msgCollectionRef, where("uidEvent", "==", paramsIdEvent.idEvent), orderBy("sendDate"), limit(50));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const messagesItems = [];
    querySnapshot.forEach((doc) => {
        messagesItems.push(
          {
            ...doc.data(),
            id: doc.id,
            isUser: doc.data().uidUser === user.uid,
            isAdmin: doc.data().type === 'admin',
          }
        );
    });
    setMessages(messagesItems);
    });
    return () => unsubscribe();
  }

  const sendMessage = async (value) => {
    const msgRef = collection(firestore, "MessageSports");
    const newDate = new Date();
    const docData = {
      text: value,
      uidUser: user.uid,
      uidEvent: paramsIdEvent.idEvent,
      sendDate: newDate,
      name: userInfo.userName,
      type: userInfo.type === 'admin' ? 'admin' : 'active',
    };
    await addDoc(msgRef, docData);
  }

  const deleteMSGAdmin = async (id) => {
    const msgDoc = doc(firestore, "MessageSports", id);
    return deleteDoc(msgDoc);
  }

  useEffect(() => {
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Chat messages={messages} handleSend={sendMessage} active={active} handleDelete={deleteMSGAdmin} isAdmin={userInfo.type === 'admin'}/>
  );
};

export default ChatEvent;
