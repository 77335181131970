import React from 'react';
import styled from 'styled-components';

const TableContainer = styled.div`
  border-radius: 10px; /* Bordes redondeados para la tabla */
  overflow: hidden; /* Asegúrate de que el contenido no se desborde */
  border: 1px solid #1C1C1C; /* Borde de la tabla */
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledThead = styled.thead`
  background-color: #1A1A1A; /* Fondo del encabezado */
  color: #EDF2F7; /* Color del texto del encabezado */
`;

const StyledTh = styled.th`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #1C1C1C;
`;

const StyledTbody = styled.tbody`
  background-color: #141414; /* Fondo de las filas */
  color: #EDF2F7;
`;

const StyledTr = styled.tr`
  &:hover {
    background-color: #1A1A1A; /* Fondo de las filas al pasar el ratón */
  }
`;

const StyledTd = styled.td`
  padding: 8px;
  border-bottom: 1px solid #1C1C1C;
`;

const CustomTable = ({columns, data}) => {

  return (
    <TableContainer>
      <StyledTable>
        <StyledThead>
          <tr>
            {columns.map((column, index) => (
              <StyledTh key={index}>{column.title}</StyledTh>
            ))}
          </tr>
        </StyledThead>
        <StyledTbody>
          {data.map((item, index) => (
            <StyledTr key={index}>
              {columns.map((column, index) => (
                <StyledTd key={index}>
                  {column.render ? column.render(item[column.key], item, index) : item[column.key]}
                </StyledTd>
              ))}
            </StyledTr>
          ))}
        </StyledTbody>
      </StyledTable>
    </TableContainer>
  );
};

export default CustomTable;
