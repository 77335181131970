import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import { useUserAuth } from "../context/UserAuthContext";
import { styled } from '@mui/material/styles';

import { Button } from "./ui";
import Logo from "../Images/xtremo-logo 1.png";

const CssTextFiel = styled(TextField)({
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
    '&:hover fieldset': {
      borderColor: '#ffffff', // Cambia el color del borde en hover
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ffffff',
  },
  '& .MuiInputLabel-root': {
    color: '#ffffff',
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
    },
  },
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    filter: 'invert(1)', // Cambia el color del ícono aquí
  },
});

const Signup = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setPasswordConfirm] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [active, setActive] = useState(false);
  const { signUp, createUserData } = useUserAuth();
  const navigate = useNavigate();

  //DB data
  const [userName, setName] = useState("");

  function refreshPage() {
    window.location.reload(false);
  }

  function isOver18(birthdate) {
    const birthDateParts = birthdate.split('-');
    const birthDateObject = new Date(birthDateParts[0], birthDateParts[1] - 1, birthDateParts[2]);

    const currentDate = new Date();
    const eighteenYearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());

    return birthDateObject <= eighteenYearsAgo;
  }

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const isUserOver18 = isOver18(birthDate);
    if (!validateEmail(email)) {
      setError("Ingresa un correo electrónico valido");
      return;
    }
    if (!isUserOver18) {
      setError("Debes ser mayor de 18 años para registrarte");
      return;
    }
    if (!active) {
      setError("Debes aceptar los términos y condiciones");
      return;
    }
    if (password !== confirmPassword) {
      setError("Comprueba que tus contraseñas coincidan");
      return;
    }
    if (userName === "") {
      setError("Comprueba tu nombre de usuario");
      return;
    }
    try {
      const { user } = await signUp(email, password);
      const currentDate = new Date();
      const newUser = {
        userName,
        email,
        balance: 0,
        address: "",
        bankName: "",
        cardNumber: 0,
        city: "",
        clave: "",
        country: "",
        joinDay: currentDate,
        lastLogin: currentDate,
        phoneNumber: "",
        state: "",
        status: true,
        type: "",
        zipCode: 0,
        totalBets: 0,
        ganancias: 0,
        perdidas: 0,
        birthDate
      };
      await createUserData(user, newUser);
      navigate("/homeuser")
      refreshPage();
    } catch (err) {
      console.log(err.code)
      switch (err.code) {
        case "auth/invalid-email":
          setError("¡Comprueba tu correo electrónico!");
          break;
        case "auth/user-not-found":
          setError("¡Usuario no encontrado, revisa tu correo!");
          break;
        case "auth/wrong-password":
          setError("¡Revisa que tu contraseña este bien escrita!");
          break;
        case "auth/weak-password":
          setError("¡Tu contraseña debe tener por lo menos 6 dígitos!");
          break;
        case "auth/email-already-in-use":
          setError("¡Este correo ya esta registrado!");
          break;
          case "auth/missing-email":
            setError("¡Comprueba tu correo electrónico!");
            break;
        default:
          //En caso de error inesperado se recarga la ventana
          //window.location.reload(false);
          setError("Error inesperado");
      }
      //
    }
  };

  return (
    <div className="container-login">
      <div className="container-login-image">
        <img src={Logo} alt="login" />
      </div>
      <Typography component="h1" variant="h4" color={'#FFFFFF'} sx={{ mt: 0, mb: 0 }} style={{ fontStyle: "italic", fontWeight: "bolder", textAlign: 'center' }}>
        Regístrate para jugar
      </Typography>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {error && <Alert severity="error">{error}</Alert>}
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="nombre"
              label="Nombre completo"
              name="name"
              autoComplete="Nombre"
              onChange={(e) => setName(e.target.value)}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <p style={{ color: "rgb(255 255 255 / 70%)", fontSize: "11px" }}>Debe ser de 6 dígitos</p>
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              name="passwordConfirm"
              label="confirmar contraseña"
              type="password"
              id="passwordConfirm"
              autoComplete="current-password"
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <CssTextFiel
              id="date"
              label="Fecha de nacimiento"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginTop: "15px", width: "100%" }}
              onChange={(e) => setBirthDate(e.target.value)}
            />
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "15px" }}>
              <Checkbox checked={active} style={{color: "#fff"}} onClick={() => setActive(!active)} />
              <Typography style={{ color: "#ffffff" }} variant="body2" gutterBottom>
                Confirmo que tengo más de 18 años y acepto los <Link to="/terminos-condiciones" target="_blank">términos y condiciones</Link>
              </Typography>
            </div>
            <Button
              type="submit"
              style={{ marginTop: '15px', height: '45px', fontSize: '18px', marginBottom: '10px' }}
            >
              REGISTRARME
            </Button>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Grid item xs={8} container direction="row" alignItems="center" justifyContent="center" sx={{ mb: 10 }}>
                <Typography sx={{ mb: 2 }} style={{ textAlign: "center", color: "#ffffff" }}>
                  Si ya tienes una cuenta&nbsp;
                  <Typography sx={{ mb: 2 }} noWrap component={Link} to="/login" style={{ textAlign: "center", color: "#ff9900" }}>
                    inicia sesión aquí.
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Signup;