import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './SlidingMenu.css';

const DraggableMenu = ({ isOpen, handleClose, children, showBackArrow, onClickBack }) => {
  const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'horizontal' : 'vertical');
  const containerRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setOrientation(window.innerWidth > window.innerHeight ? 'horizontal' : 'vertical');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClose]);

  return (
    <div className="sliding-menu-container" ref={containerRef}>
      <motion.div
        ref={menuRef}
        initial={{ y: orientation === 'vertical' ? '100%' : 0, x: orientation === 'horizontal' ? '100%' : 0 }}
        animate={{ y: isOpen ? 0 : orientation === 'vertical' ? '100%' : 0, x: isOpen ? 0 : orientation === 'horizontal' ? '100%' : 0 }}
        transition={{ type: 'spring', stiffness: 200, damping: 27 }}
        className={`sliding-menu ${orientation}`}
      >
        <div className="menu-header">
          {showBackArrow && <ArrowBackIcon className="back-icon" onClick={onClickBack} />}
          <h2 className="menu-title">Menu</h2>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>
        <>
          {children}
        </>
      </motion.div>
    </div>
  );
};

export default DraggableMenu;