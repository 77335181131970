
import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';
import { useLocalStorage } from '../useLocalStorage';
import { dbFights, db } from "../firebase";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import './PeleasActuales.css'
import './FightTable.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import LogoGallo from "../Images/rooster.png";
import NavBarLeftVertical from './NavBarLeftVertical';

const TableRowStyled = styled(TableRow)({
    '& .MuiTableCell-root': {
        borderBottom: '1px solid #1F1F1F',
    },
});

const TableHeadStyled = styled(TableHead)({
    '& .MuiTableRow-root': {
        background: "#1A1A1A",
    },
});

const useStyles = makeStyles({
    gridContainer: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "100px",
        paddingTop: "30px"
    },
    card: {
        minWidth: 320,
        position: 'relative',
        boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
        overflow: 'visible',
        borderRadius: '1.5rem',
        transition: '0.4s',
        '&:hover': {
            transform: 'translateY(-2px)',
            '& $shadow': {
                bottom: '-1.5rem',
            },
            '& $shadow2': {
                bottom: '-2.5rem',
            },
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: 0,
            display: 'block',
            width: '100%',
            bottom: -1,
            height: '100%',
            borderRadius: '1.5rem',
            backgroundColor: 'rgba(0,0,0,0.08)',
        },
    },
    main: {
        overflow: 'hidden',
        borderTopLeftRadius: '1.5rem',
        borderTopRightRadius: '1.5rem',
        zIndex: 1,
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            display: 'block',
            width: '100%',
            height: '100%',
            background: 'linear-gradient(to top, #014a7d, rgba(0,0,0,0))',
        },
    },
    content: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        zIndex: 1,
        padding: '1.5rem 1.5rem 1rem',
    },
    avatar: {
        width: 48,
        height: 48,
    },
    tag: {
        display: 'inline-block',
        fontFamily: "'Sen', sans-serif",
        fontSize: "12px",
        backgroundColor: '#DC1515',
        borderRadius: '6px',
        padding: '2px 5px 2px 5px;',
        color: '#fff',
        marginBottom: '0.5rem',
    },
    tagBetOpen: {
        marginLeft: "auto",
        marginRight: "5px",
        marginTop: "-35px",
        position: "relative",
        display: 'block',
        width: "fit-content",
        textAlign: "center",
        backgroundColor: '#fff',
        borderRadius: '1rem',
        padding: '2px 1rem',
        marginBottom: '1.2rem',
    },
    tagBetClose: {
        marginLeft: "auto",
        marginRight: "5px",
        marginTop: "-35px",
        position: "relative",
        display: 'block',
        width: "fit-content",
        textAlign: "center",
        backgroundColor: '#781113',
        borderRadius: '1rem',
        padding: '2px 1rem',
        marginBottom: '1.2rem',
    },
    tagNormal: {
        marginLeft: "0px",
        display: 'block',
        width: "fit-content",
        textAlign: "initial",
        backgroundColor: '#b35426',
        borderRadius: '1rem',
        padding: '2px 1rem',
        marginBottom: '1rem',
        color: "#fff"
    },
    title: {
        display: 'inline-block',
        fontFamily: "'Sen', sans-serif",
        fontSize: '2rem',
        fontWeight: 800,
        color: '#fff',
    },
    author: {
        zIndex: 1,
        position: 'relative',
        borderBottomLeftRadius: '1.5rem',
        borderBottomRightRadius: '1.5rem',
    },
    shadow: {
        transition: '0.2s',
        position: 'absolute',
        zIndex: 0,
        width: '88%',
        height: '100%',
        bottom: 0,
        borderRadius: '1.5rem',
        backgroundColor: 'rgba(0,0,0,0.06)',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    shadow2: {
        bottom: 0,
        width: '72%',
        backgroundColor: 'rgba(0,0,0,0.04)',
    },
    action: {
        backgroundColor: '#fff',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#000',
        },
    },
    anticon: {
        color: "#fff"
    },
    typography: {
        fontFamily: "Outfit",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "16.8px",


    }
});

const FightTable = () => {
    const { user } = useUserAuth();
    const classes = useStyles();
    const [, setUidUser] = useLocalStorage("uidUser", "");
    const [fights, setFights] = useState([]);
    const [sports, setSports] = useState([]);
    const [fightsLive, setFightsLive] = useState([]);
    const [fightsLocations, setFightsLocations] = useState([]);
    const [fightsFilterLocations, setFightsFilterLocations] = useState([]);
    const [evetLocation, setEventLocation] = useState([]);
    //this variable checks if we have more than one fight

    //images

    const getFightsRealTime = async () => {
        const fightsCollectionRef = collection(dbFights, "Fights");
        const q = query(fightsCollectionRef, where("ended", "==", false));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let items = [];
            let itemsLive = [];
            let itemsLocations = []
            let itemsLocationsEvents = []

            querySnapshot.forEach((doc) => {
                if (doc.data().privateEvent || doc.data().status === false) {
                    return;
                }
                if (doc.data().live) {
                    itemsLive.push({ ...doc.data(), id: doc.id });
                } else {
                    items.push({ ...doc.data(), id: doc.id });
                }
                if (doc.data().status && !doc.data().privateEvent) {
                    itemsLocations.push({ ...doc.data(), id: doc.id })
                    itemsLocationsEvents.push(doc.data().location)
                }
            });

            items.sort(function (a, b) {
                const dateA = a.date;
                const dateB = b.date;
                if (dateB < dateA) {
                    return -1;
                }
                if (dateB > dateA) {
                    return 1;
                }
                return 0;
            });

            itemsLive.sort(function (a, b) {
                const dateA = a.date;
                const dateB = b.date;
                if (dateB < dateA) {
                    return -1;
                }
                if (dateB > dateA) {
                    return 1;
                }
                return 0;
            });

            let newArray = [];
            let lookupObject = {};

            for (let i in itemsLocations) {
                lookupObject[itemsLocations[i]["location"]] = itemsLocations[i];
            }

            for (let i in lookupObject) {
                newArray.push(lookupObject[i]);
            }

            setFightsLive(itemsLive);
            setFights(items);
            setFightsLocations(newArray)
            setFightsFilterLocations([])
            let fightsFilterLocation = [];
            let eventLocations = [];
            itemsLive.filter((item) => {
                if (item.location === newArray[0].location) {
                    fightsFilterLocation.push(item)
                    eventLocations.push(item.location)
                    setFightsFilterLocations(fightsFilterLocation)
                    setEventLocation(eventLocations)
                }
                return false
            })

            items.filter((item) => {
                if (item.location === newArray[0].location) {
                    fightsFilterLocation.push(item)
                    eventLocations.push(item.location)
                    setFightsFilterLocations(fightsFilterLocation)
                    setEventLocation(eventLocations)
                }
                return false;
            })
        });

        return () => unsubscribe();
    }

    const getSportsRealTime = async () => {
        const sportsCollectionRef = collection(db, "Events");
        const q = query(sportsCollectionRef, where("ended", "==", false));
        let items = [];
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().live) {
                    items.push(doc.data().category);
                  } else {
                    items.push(doc.data().category);
                  }
            });

            
        });
        setSports(items)
        return () => unsubscribe();
    }

    // request a weekday along with a long date


    const dataSave = (idUser) => {
        //This save function helps reload data in fightView
        setUidUser(idUser);
    }


    const handleClick = (locationTag, eventSelect) => {
        let fightsFilterLocation = []
        let eventLocations = []

        fightsLive.filter((item) => {
            if (item.location === locationTag) {
                fightsFilterLocation.push(item)
                eventLocations.push(item.location)
                setFightsFilterLocations(fightsFilterLocation)
                setEventLocation(eventLocations)
            }
            return false
        })

        fights.filter((item) => {
            if (item.location === locationTag) {
                fightsFilterLocation.push(item)
                eventLocations.push(item.location)
                setFightsFilterLocations(fightsFilterLocation)
                setEventLocation(eventLocations)
            }
            return false;
        })
    };

    useEffect(() => {
        getFightsRealTime();
        getSportsRealTime();
    }, []);

    return (
        <Container component="main" maxWidth="xxl" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            {/* desktop */}
            <div className="container-fight-table">
                <div className='container-fight-table-filters-horizontal'>
                    <div style={{ display: "flex", width: "100%", alignItems: "center", margin: "0em 1em 0em 1em" }}>
                        {fightsLocations.map((doc, index) => {
                            return (
                                <>
                                    {fightsLocations.length > 1 &&
                                        <div className={evetLocation.includes(doc.location) ? 'filter-location-selected' : 'filter-location'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleClick(doc.location, doc);
                                            }}>
                                            <img alt='' src={LogoGallo} style={{ width: "30px", height: "30px", marginRight: "5px", color: "#38A169" }} />{doc.location.toUpperCase()}
                                        </div>
                                    }
                                </>
                            );
                        })}
                    </div>
                </div>
                <div className='container-fight-table-filters-vertical'>
                    <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden",  marginRight: "6px" }}>
                        {fightsLocations.map((doc, index) => {
                            return (
                                <>
                                    {fightsLocations.length > 1 &&
                                        <div className={evetLocation.includes(doc.location) ? 'filter-location-selected' : 'filter-location'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleClick(doc.location, doc);
                                            }}>
                                            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden",  marginRight: "6px" }}>{doc.location.toUpperCase()}</div>
                                        </div>
                                    }
                                </>
                            );
                        })}
                    </div>
                </div>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <TableContainer >
                        <Table sx={{ minWidth: 250 }} style={{ backgroundColor: "rgb(20, 20, 20)" }} aria-label="simple table">
                            <TableHeadStyled>
                                <TableRowStyled>
                                    <TableCell className={classes.typography} style={{ color: "#ffffff" }} align="center">Fecha</TableCell>
                                    <TableCell className={classes.typography} style={{ color: "#ffffff" }} align="center">Pelea</TableCell>
                                    <TableCell className={classes.typography} style={{ color: "#ffffff" }} align="center">Lugar</TableCell>
                                    <TableCell className={classes.typography} style={{ color: "#ffffff" }} align="center"></TableCell>
                                </TableRowStyled>
                            </TableHeadStyled>
                            <TableBody>
                                {fightsFilterLocations.map((doc, index) => (
                                    <TableRowStyled
                                        key={doc.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        component={Link} to={`/fight-view/${doc.id}`} onClick={(e) => dataSave(user.uid)}
                                    >
                                        <TableCell component="th" scope="row" align="center" width={300}>
                                            <div style={{ display: "flex", justifyContent: "left" }}>
                                                <div style={{ width: "50%", display: "flex", justifyContent: "right", marginRight: "2em" }}>
                                                    <Typography sx={{ fontSize: 14 }} style={{ marginRight: "2em", color: "#fff", styleName: "component-text/sm", fontFamily: "Outfit", fontSize: "14px", fontWeight: "400", lineHeight: "21px", textAlign: "left" }}>
                                                        {new Date(doc.time * 1000).getHours() + ":" + (new Date(doc.time * 1000).getMinutes() < 10 ? '0' : '') + new Date(doc.time * 1000).getMinutes()}
                                                    </Typography>
                                                </div>
                                                {doc.live &&
                                                    <div style={{ width: "50%", display: "flex", justifyContent: "left", marginLeft: "2em" }}>
                                                        <Typography sx={{ fontSize: 14 }} className={classes.tag} color="#fff" gutterBottom>
                                                            En vivo
                                                        </Typography>
                                                    </div>
                                                }
                                                {!doc.live &&
                                                    <div style={{ width: "50%", display: "flex", justifyContent: "left", marginLeft: "2em" }}>
                                                        <Typography sx={{ fontSize: 14 }} gutterBottom style={{ marginRight: "2em", color: "#fff", styleName: "component-text/sm", fontFamily: "Outfit", fontSize: "14px", fontWeight: "400", lineHeight: "21px", textAlign: "left" }}>
                                                            {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", { weekday: 'long' })}
                                                        </Typography>
                                                    </div>
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                            <Typography sx={{ fontSize: 14 }} style={{ color: "#ffffff", justifyContent: "center", display: "flex" }} className={classes.typography}>{doc.fightName}</Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                            <Typography sx={{ fontSize: 14 }} style={{ color: "#ffffff", justifyContent: "center", display: "flex" }} className={classes.typography}>{doc.location}</Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                            <Typography sx={{ fontSize: 14 }} style={{ color: "#ffffff", justifyContent: "center", display: "flex" }} className={classes.typography}>
                                                <Link to={`/fight-view/${doc.id}`}><div style={{ fontWeight: "100", color: "#fff", background: "#38A169", width: "71px", height: "32px", borderRadius: "6px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px" }}>Ver evento</div></Link>
                                            </Typography>
                                        </TableCell>
                                    </TableRowStyled>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <div style={{ height: "45px" }}></div>
            </div>
            {/* mobile */}
            <div className="container-fight-table-mobile">
                <div className="container-panel-left-vertical">
                    {fights &&
                        <NavBarLeftVertical fights={fights} sports ={sports} />
                    }
                </div>
                <div style={{ margin: "2em", paddingBottom: "3em", background: "rgb(20, 20, 20)", borderRadius: "15px 15px 10px 10px" }}>
                    <div className='container-fight-table-filters-horizontal'>
                        <div style={{ display: "flex", width: "100%", alignItems: "center", margin: "0em 1em 0em 1em" }}>
                            {fightsLocations.map((doc, index) => {
                                return (
                                    <>
                                        {fightsLocations.length > 1 &&
                                            <div className={evetLocation.includes(doc.location) ? 'filter-location-selected' : 'filter-location'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleClick(doc.location, doc);
                                                }}>
                                                <img alt='' src={LogoGallo} style={{ width: "30px", height: "30px", marginRight: "5px", color: "#38A169" }} /><div style={{ width: "100%", display: "flex", justifyContent: "left" }}>{doc.location.toUpperCase()}</div>
                                            </div>
                                        }
                                    </>
                                );
                            })}
                        </div>
                    </div>
                    <div className='container-fight-table-filters-vertical'>
                        <div style={{ display: "flex", width: "100%", alignItems: "center", margin: "0em -1em 0em 0em" }}>
                            {fightsLocations.map((doc, index) => {
                                return (
                                    <>
                                        {fightsLocations.length > 1 &&
                                            <div className={evetLocation.includes(doc.location) ? 'filter-location-selected' : 'filter-location'}
                                            style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", marginRight: "6px" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleClick(doc.location, doc);
                                                }}>
                                                <div  style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden",  marginRight: "6px" }}>{doc.location.toUpperCase()}</div>
                                            </div>
                                        }
                                    </>
                                );
                            })}
                        </div>
                    </div>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <TableContainer >
                            <Table sx={{ minWidth: 250 }} style={{ backgroundColor: "rgb(20, 20, 20)" }} aria-label="simple table">
                                <TableHeadStyled>
                                    <TableRowStyled>
                                        <TableCell className={classes.typography} style={{ color: "#ffffff" }} align="center">Pelea</TableCell>
                                        <TableCell className={classes.typography} style={{ color: "#ffffff" }} align="center"></TableCell>
                                    </TableRowStyled>
                                </TableHeadStyled>
                                <TableBody>
                                    {fightsFilterLocations.map((doc, index) => (
                                        <TableRowStyled
                                            key={doc.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            component={Link} to={`/fight-view/${doc.id}`} onClick={(e) => dataSave(user.uid)}
                                        >
                                            <TableCell component="th" scope="row" align="center">
                                                <Typography sx={{ fontSize: 14 }} style={{ color: "#ffffff", justifyContent: "left", display: "flex", marginBottom: "6px", textAlign: "left", width: "65%", lineHeight: "20.8px" }} className={classes.typography}>{doc.fightName}</Typography>
                                                <div style={{ display: "flex", justifyContent: "left" }}>
                                                    <div style={{ width: "35%", display: "flex", justifyContent: "left", marginRight: "5px" }}>
                                                        <Typography sx={{ fontSize: 14 }} style={{ marginRight: "5px", color: "#fff", styleName: "component-text/sm", fontFamily: "Outfit", fontSize: "11px", fontWeight: "400", lineHeight: "21px", textAlign: "left" }}>
                                                            {new Date(doc.date * 1000).getHours() + ":" + (new Date(doc.date * 1000).getMinutes() < 10 ? '0' : '') + new Date(doc.date * 1000).getMinutes()}
                                                        </Typography>
                                                    </div>
                                                    {doc.live &&
                                                        <div style={{ width: "65%%", display: "flex", justifyContent: "left" }}>
                                                            <Typography sx={{ fontSize: 14 }} className={classes.tag} color="#fff" gutterBottom>
                                                                En vivo
                                                            </Typography>
                                                        </div>
                                                    }
                                                    {!doc.live &&
                                                        <div style={{ width: "65%", display: "flex", justifyContent: "left" }}>
                                                            <Typography sx={{ fontSize: 14 }} gutterBottom style={{ marginRight: "2em", color: "#fff", styleName: "component-text/sm", fontFamily: "Outfit", fontSize: "11px", fontWeight: "400", lineHeight: "21px", textAlign: "left" }}>
                                                                {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", { weekday: 'long' })}
                                                            </Typography>
                                                        </div>
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                                <Typography sx={{ fontSize: 14 }} style={{ color: "#ffffff", justifyContent: "center", display: "flex" }} className={classes.typography}>
                                                    <Link to={`/fight-view/${doc.id}`}><div style={{ fontWeight: "100", color: "#fff", background: "#38A169", width: "71px", height: "32px", borderRadius: "6px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px" }}>Ver evento</div></Link>
                                                </Typography>
                                            </TableCell>
                                        </TableRowStyled>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </div>
            </div>
            <div style={{ height: "45px" }}></div>
        </Container>
    );
};

export default FightTable;