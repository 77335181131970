import styled from "styled-components";

const Button = styled.button`
  background-color: #141414;
  border-radius: 10px;
  padding: 2rem;
  transition: all 0.3s ease;
  height: 80px;
  width: 90px;
  border: 1px solid #1C1C1C;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.p`
  color: #EDF2F7;
  font-size: 14px;
  text-align: center;
  padding: 0;
  margin: 0;
`;

const ButtonMenu = ({ title, icon, onClick }) => {
  return (
    <Button onClick={onClick}>
      {icon}
      <Content>{title}</Content>
    </Button>
  );
};

export default ButtonMenu;
