import React from 'react';
import styled from 'styled-components';
import SendIcon from "@mui/icons-material/Send";

const Container = styled.div`
  font-family: Arial, sans-serif;
  border: 1px solid #1C1C1C;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 40px;
  right: 10px;
  background-color: #141414;
  width: 250px;
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #1C1C1C;
  padding: 5px;
  margin-bottom: 10px;
`;

const MessageContainer = styled.div`
  padding: 10px;
  height: 160px;
  overflow-y: scroll;
`;

const Message = styled.p`
  margin: 0;
  color: #fff;
`;

const InputContainer = styled.div`
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  padding: 5px;
  flex: 1;
  border: 1px solid #1C1C1C;
  background-color: #141414;
  color: #fff;
  border-radius: 4px;
  margin-right: 8px;
`;

const ChatIframe = ({ messages, visible, handleSend, toggleChat, active }) => {
  const [inputValue, setInputValue] = React.useState('');

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      handleSend(inputValue);
      setInputValue('');
    }
  };

  return (
    <Container visible={visible}>
      <Header>
        <button style={{ border: 'none', backgroundColor: 'transparent', color: '#fff', cursor: 'pointer' }} onClick={toggleChat}>X</button>
      </Header>
      <MessageContainer>
        {messages.map((message, index) => (
          <Message key={index}>
            {message.name}: {message.text}
          </Message>
        ))}
      </MessageContainer>
      <InputContainer>
        {active ? (<>
          <Input
            type="text"
            value={inputValue}
            placeholder='Escribe un mensaje...'
            onChange={(e) => setInputValue(e.target.value)}
          />
          <SendIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={handleSendMessage}
          />
        </>) : (
          <span style={{ color: '#fff' }}>Chat deshabilitado</span>
        )}

      </InputContainer>
    </Container>
  );
};

export default ChatIframe;