import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { useUserAuth } from "../context/UserAuthContext";
import { firestore } from "../firebase";
import { collection, getDoc, getDocs, increment, updateDoc, doc, query, where, serverTimestamp, orderBy, getCountFromServer, limit, startAfter } from "firebase/firestore";
import HistoryDataService from "../Services/history.services";
import CircularProgress from '@mui/material/CircularProgress';
import { Button as ButtonAnt } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import style from "styled-components";

const useStyles = makeStyles({
    typography: {
        fontFamily: "Outfit",
        fontSize: "14px",
        lineHeight: "16.8px",
        textAlign: "center",
        color: "#fff"
    }
});


const TableRowStyled = styled(TableRow)({
    '& .MuiTableCell-root': {
        borderBottom: '1px solid #1F1F1F',
        background: "#1A1A1A",
    },
});

const CssTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        background: "rgb(56, 161, 105)",
    },
});

const ContainerGeneral = style.div`
    padding: 1em 5em 2em;
    background: rgb(14, 14, 14);

@media screen and (min-width: 0px) and (max-width: 1000px) {
        padding: 1em;
}
`;

const ManageDeposits = () => {
    const { user, getUserData } = useUserAuth();
    const [deposits, setDeposits] = useState([]);
    const [approvedDeposits, setApprovedDeposits] = useState([]);
    const depositsCollectionRef = collection(firestore, "Deposits");
    const dateOptions = {
        month: "long",
        day: "numeric",
    };
    const [auid, setAuid] = useState("");
    const [totalWaitings, setTotalWaitings] = useState(0);
    const classes = useStyles();


    //Withdrawals
    const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
    const [withdrawals, setWithdrawals] = useState([]);
    const [withdrawalsReview, setWithdrawalsReview] = useState([]);
    const [totalWaitingsWithdrawals, setTotalWaitingsWithdrawals] = useState(0);
    const [buttonPressed, setButtonPressed] = useState(false);
    const [loading, setLoading] = useState(false);

    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 5,
        pageStarts: [null],
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationWithdrawals, setPaginationWithdrawals] = useState({
        page: 1,
        pageSize: 5,
        pageStarts: [null],
    });
    const [currentPageWithdrawals, setCurrentPageWithdrawals] = useState(1);
    const [paginationApproved, setPaginationApproved] = useState({
        page: 1,
        pageSize: 5,
        pageStarts: [null],
    });
    const [currentPageApproved, setCurrentPageApproved] = useState(1);
    const [paginationWithdrawalsReview, setPaginationWithdrawalsReview] = useState({
        page: 1,
        pageSize: 5,
        pageStarts: [null],
    });
    const [currentPageWithdrawalsReview, setCurrentPageWithdrawalsReview] = useState(1);
    const [listSize, setListSize] = useState(null);
    const [listSizeWithdrawals, setListSizeWithdrawals] = useState(null);
    const [listSizeWithdrawalsReview, setListSizeWithdrawalsReview] = useState(null);
    const [listSizeReviews, setListSizReviews] = useState(null);

    //Data History ================================================
    const createHistory = async (movementType, description, uidUser, amount) => {
        let initialValueH = 0;
        let movementTypeH = movementType;
        let descriptionH = description;
        let newBalanceH = 0;
        let uidFightH = "";
        let uidUserH = uidUser;
        const newDate = new Date();

        const docRef = doc(firestore, "Users", uidUser);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            initialValueH = docSnap.data().balance;
            newBalanceH = initialValueH + amount;
        } else {
            return console.log("No such document!");
        }

        const docData = {
            initialValue: initialValueH,
            newBalance: newBalanceH,
            movementType: movementTypeH,
            description: descriptionH,
            uidUser: uidUserH,
            uidFight: uidFightH,
            date: newDate
        };
        await HistoryDataService.addHistory(docData);
        setButtonPressed(false)
        setLoading(false)
    }
    //===============================================================


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        setPagination(prev => ({ ...prev, page: newPage }));
        getDeposits(newPage);
    };

    const handlePageChangeWithdrawals = (newPage) => {
        setCurrentPageWithdrawals(newPage);
        setPaginationWithdrawals(prev => ({ ...prev, page: newPage }));
        getWithdrawals(newPage);
    };

    const handlePageChangeApprovedReviews = (newPage) => {
        setCurrentPageApproved(newPage);
        setPaginationApproved(prev => ({ ...prev, page: newPage }));
        getDepositsApproved(newPage);
    };

    const handlePageChangeWithdrawalsReview = (newPage) => {
        setCurrentPageWithdrawalsReview(newPage);
        setPaginationWithdrawalsReview(prev => ({ ...prev, page: newPage }));
        getWithdrawalsReviews(newPage);
    };

    //Deposits get from firebase
    const getWithdrawals = async (page) => {
        const { pageSize, pageStarts } = paginationWithdrawals;
        const startAfterPage = pageStarts[page - 1];
        let q = query(withdrawalsCollectionRef,
            orderBy("status", "desc"),
            orderBy("withdrawalDate", "desc"),
            limit(pageSize));

        if (startAfterPage) {
            q = query(q, startAfter(startAfterPage));
        }

        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs;
        const items = [];
        querySnapshot.forEach((doc) => {
            if (doc.data().status === "waiting") {
                items.push({ ...doc.data(), id: doc.id });
            }

        });
        setWithdrawals(items);

        if (page === pageStarts.length && docs.length === pageSize) {
            pageStarts.push(docs[docs.length - 1]);
        }
    };

    const getWithdrawalsReviews = async (page) => {
        const { pageSize, pageStarts } = paginationWithdrawalsReview;
        const startAfterPage = pageStarts[page - 1];
        let q = query(withdrawalsCollectionRef,
            where("approvedDate", "!=", ""),
            orderBy("approvedDate", "desc"),
            limit(pageSize));

        if (startAfterPage) {
            q = query(q, startAfter(startAfterPage));
        }

        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs;
        const itemsReview = [];
        querySnapshot.forEach((doc) => {
            itemsReview.push({ ...doc.data(), id: doc.id });

        });

        setWithdrawalsReview(itemsReview)

        if (page === pageStarts.length && docs.length === pageSize) {
            pageStarts.push(docs[docs.length - 1]);
        }
    };

    const getDepositsCount = async () => {
        let q = query(depositsCollectionRef,
            where("status", "==", "waiting"))
        const snapshotCount = await getCountFromServer(q);
        setListSize(snapshotCount.data().count);
    };

    const getRetirosCount = async () => {
        let q = query(withdrawalsCollectionRef,
            where("status", "==", "waiting"))

        const snapshotCount = await getCountFromServer(q);
        setListSizeWithdrawals(snapshotCount.data().count);
    };

    const getRetirosReviewCount = async () => {
        let q = query(withdrawalsCollectionRef,
            where("approvedDate", "!=", ""));
        const snapshotCount = await getCountFromServer(q);
        setListSizeWithdrawalsReview(snapshotCount.data().count);
    };

    const getReviewsCount = async () => {
        let q = query(depositsCollectionRef,
            where("approvedDate", "!=", ""))
        const snapshotCount = await getCountFromServer(q);
        setListSizReviews(snapshotCount.data().count);

    };

    //Deposits get from firebase
    const getDeposits = async (page) => {
        const { pageSize, pageStarts } = pagination;
        const startAfterPage = pageStarts[page - 1];
        let q = query(depositsCollectionRef,
            orderBy("status", "desc"),
            limit(pageSize));


        if (startAfterPage) {
            q = query(q, startAfter(startAfterPage));
        }

        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs;

        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setDeposits(items);

        if (page === pageStarts.length && docs.length === pageSize) {
            pageStarts.push(docs[docs.length - 1]);
        }
    };

    const getWaitings = async (page) => {
        const { pageSize, pageStarts } = pagination;
        const startAfterPage = pageStarts[page - 1];
        let q = query(depositsCollectionRef,
            where("status", "==", "waiting"))

        if (startAfterPage) {
            q = query(q, startAfter(startAfterPage));
        }

        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs;
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setTotalWaitings(items.length);

        if (page === pageStarts.length && docs.length === pageSize) {
            pageStarts.push(docs[docs.length - 1]);
        }
    };

    const getWaitingsWithdrawals = async () => {

        let q = query(withdrawalsCollectionRef, where("status", "==", "waiting"));
        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setTotalWaitingsWithdrawals(items.length);

    };

    const getDepositsApproved = async (page) => {
        const { pageSize, pageStarts } = paginationApproved;
        const startAfterPage = pageStarts[page - 1];
        let q = query(depositsCollectionRef,
            where("approvedDate", "!=", ""),
            orderBy("approvedDate", "desc"),
            limit(pageSize));

        if (startAfterPage) {
            q = query(q, startAfter(startAfterPage));
        }

        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs;
        const items = [];
        querySnapshot.forEach((doc) => {

            items.push({ ...doc.data(), id: doc.id });

        });


        setApprovedDeposits(items);
        if (page === pageStarts.length && docs.length === pageSize) {
            pageStarts.push(docs[docs.length - 1]);
        }

    };

    const HandleApproved = async (id, amount) => {
        setButtonPressed(true)
        setLoading(true)
        const depositRef = doc(firestore, "Deposits", id);
        await updateDoc(depositRef, {
            approvedDate: serverTimestamp(),
            aui: user.uid,
            authorizedUserName: auid,
            status: "approved"
        });
        getDeposits(pagination.page);
        getDepositsApproved(paginationApproved.page);
        //Add deposit
        const docRef = doc(firestore, "Deposits", id);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            console.log("No such document!");
        }
        //Add the new balance amount to the previus one
        const userRef = doc(firestore, "Users", docSnap.data().uid);
        await updateDoc(userRef, {
            balance: increment(amount),
            deposits: increment(1),
            depositsTotal: increment(amount)
        });
        //Create history
        let type = "Deposito aprobado";
        let description = ("Deposito aprobado: $" + amount);
        createHistory(type, description, docSnap.data().uid, amount);
    };

    const HandleDenied = async (id) => {
        const depositRef = doc(firestore, "Deposits", id);
        await updateDoc(depositRef, {
            approvedDate: serverTimestamp(),
            aui: user.uid,
            authorizedUserName: auid,
            status: "denied"
        });
        getDeposits(pagination.page);
        getDepositsApproved(paginationApproved.page);
    };

    const HandleVoucher = async (voucher) => {
        window.open(voucher, '_blank', 'noreferrer');
    };

    const getNameAUID = async () => {
        if (!user.uid) {
            return;
        } else {
            await getUserData(user).then((snapshot) => {
                if (snapshot === null || snapshot.data().userName === null) {
                    window.location.reload(false);
                    return;
                }
                setAuid(snapshot.data().userName);
            });
        }
    };

    const HandleWithdrawalApproved = async (id) => {
        const withdrawalRef = doc(firestore, "Withdrawals", id);
        await updateDoc(withdrawalRef, {
            approvedDate: serverTimestamp(),
            aui: user.uid,
            authorizedUserName: auid,
            status: "approved"
        });
        getWithdrawals(paginationWithdrawals.page);
        getWithdrawalsReviews(paginationWithdrawalsReview.page);
    };

    const HandleWithdrawalDeclined = async (id, amount) => {
        const withdrawalRef = doc(firestore, "Withdrawals", id);
        await updateDoc(withdrawalRef, {
            approvedDate: serverTimestamp(),
            aui: user.uid,
            authorizedUserName: auid,
            status: "declined"
        });
        getWithdrawals(paginationWithdrawals.page);
        getWithdrawalsReviews(paginationWithdrawalsReview.page);

        //Refund user money 
        const docSnap = await getDoc(withdrawalRef);
        const userRef = doc(firestore, "Users", docSnap.data().uid);
        await updateDoc(userRef, {
            balance: increment(amount)
        });
    };

    useEffect(() => {
        if (user?.uid) {

            let startDate = new Date();
            startDate.setDate(startDate.getDate() - 7);
            startDate.setHours(0, 0, 0, 0);

            getDeposits(pagination.page);
            getDepositsApproved(paginationApproved.page);
            getWithdrawals(paginationWithdrawals.page);
            getWithdrawalsReviews(paginationWithdrawalsReview.page);
            getNameAUID();
            getWaitings(pagination.page);
            getWaitingsWithdrawals(pagination.page);
            getDepositsCount()
            getRetirosCount()
            getReviewsCount()
            getRetirosReviewCount()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        var stringArray = name.split(/(\s+)/);
        if (stringArray.length === 1) {
            return {
                sx: {
                    bgcolor: stringToColor(name),
                },
                children: `${name.split(' ')[0][0]}`,
            };
        }
        if (stringArray.length >= 2) {
            return {
                sx: {
                    bgcolor: stringToColor(name),
                },
                children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            };
        }
    }

    const [valueTab, setValueTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    return (
        <>
            <ContainerGeneral component="main" maxWidth="100%" style={{ background: "rgb(14, 14, 14)" }}>
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4" className={classes.typography} style={{fontSize:"26px", marginBottom:"5px"}} >
                        Movimientos
                    </Typography>
                    <Grid container spacing={2} direction="row" alignItems="inherit" style={{ display: "flex", justifyContent: "center" }}>
                        <Grid item sx={{ mb: 4 }}>
                            <CssTabs
                                value={valueTab}
                                onChange={handleChangeTab}
                                indicatorColor="secondary"
                                textColor="inherit"
                            >
                                <Tab className={classes.typography} style={{fontSize:"14px"}} label={"Ingresos (" + totalWaitings + ")"}  />
                                <Tab className={classes.typography} style={{fontSize:"14px"}} label={"Retiros (" + totalWaitingsWithdrawals + ")"} />
                            </CssTabs>
                        </Grid>
                    </Grid>
                </Box>
                {valueTab === 0 &&
                    <>
                        <TableContainer component={Paper} style={{ borderRadius: 13, background: "rgb(14, 14, 14)" }}>
                            {loading &&
                                <Grid container sx={{ display: 'flex', mb: 3, mt: 6 }} direction="column" alignItems="center" justifyContent="center">
                                    <Grid item xs={12}>
                                        <CircularProgress color="inherit" style={{ color: "#6750A4" }} />
                                    </Grid>
                                </Grid>
                            }
                            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                <TableHead>
                                    <TableRowStyled>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="left" className={classes.typography}>Usuario</TableCell>
                                        <TableCell align="center" className={classes.typography}>Fecha</TableCell>
                                        <TableCell align="right" className={classes.typography}>Cantidad</TableCell>
                                        <TableCell align="right" className={classes.typography}>Metodo</TableCell>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRowStyled>
                                </TableHead>
                                <TableBody>
                                    {deposits.map((doc, index) => (
                                        <TableRowStyled
                                            key={doc.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {doc.status === "waiting" &&
                                                <TableCell align="center">
                                                    <Avatar {...stringAvatar(doc.userName)} />
                                                </TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell component="th" scope="row" align="left" className={classes.typography}>{doc.userName}</TableCell>}
                                            {/*
                    {doc.status !== "waiting" &&
                    <TableCell component="th" scope="row" align="left" style={{fontSize:"14px", color:'gray'}}>{doc.userName}</TableCell> }  */}

                                            {doc.status === "waiting" &&
                                                <TableCell align="center" className={classes.typography}>
                                                    {new Date(doc.depositDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                                                </TableCell>}
                                            {/*
                    {doc.status !== "waiting" &&
                    <TableCell align="center" style={{fontSize:"14px", color:'gray'}}>
                    {new Date(doc.depositDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                    </TableCell>}*/}

                                            {doc.status === "waiting" &&
                                                <TableCell align="right" className={classes.typography}>${doc.amount}</TableCell>
                                            }
                                            {doc.status === "waiting" &&
                                                <TableCell align="right" className={classes.typography}>{doc.payMethod}</TableCell>
                                            }
                                            {/*
                    {doc.status !== "waiting" &&
                    <TableCell align="right" style={{fontSize:"14px", color:'gray'}}>${doc.amount}</TableCell>}*/}
                                            {doc.status === "waiting" &&
                                                <TableCell align="left">
                                                    {doc.payMethod === "default" ?
                                                    <InsertDriveFileOutlinedIcon
                                                        fontSize="inherit"
                                                        color="success"
                                                        style={{ fontSize: "22px", color: '#EA9F26', marginTop: "4px" }}
                                                        onClick={(e) => HandleVoucher(doc.voucher)}
                                                    />
                                                    :
                                                    null
                                                    }
                                                </TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="right" className={classes.typography}>
                                                    En espera
                                                </TableCell>}
                                            {/* 
                    {doc.status !== "waiting" && 
                    <TableCell align="right" style={{fontSize:"14px", color:'gray'}}>
                        {doc.status}
                    </TableCell>}*/}
                                            {doc.status === "waiting" &&
                                                <TableCell align="right" >
                                                    <ChatBubbleOutlineOutlinedIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: '#EA9F26' }} />
                                                </TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="right">
                                                    {doc.status === "waiting" &&
                                                        <Button variant="outlined" color="primary" size="small"
                                                            onClick={(e) => HandleDenied(doc.id)}
                                                            className={classes.typography}
                                                            style={{
                                                                borderRadius: 35,
                                                                padding: "8px 24px",
                                                                fontSize: "12px"
                                                            }}>
                                                            Rechazado
                                                        </Button>}
                                                </TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="left">
                                                    {(doc.status === "waiting" && !buttonPressed) &&
                                                        <Button variant="outlined" color="primary" size="small" onClick={(e) => HandleApproved(doc.id, doc.amount)}
                                                        className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 24px",
                                                            fontSize: "12px"
                                                        }}>
                                                            Aplicado
                                                        </Button>}

                                                    {(doc.status === "waiting" && buttonPressed) &&
                                                        <Button variant="outlined" color="primary" size="small"
                                                        className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 24px",
                                                            fontSize: "12px"
                                                        }}
                                                            disabled>
                                                            Aplicado
                                                        </Button>}
                                                </TableCell>}

                                        </TableRowStyled>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                            <ButtonAnt
                                disabled={currentPage === 1}
                                style={{ background: '#1a1a1a', border: 'none', color:"#fff" }}
                                onClick={() => handlePageChange(currentPage - 1)}>
                                <LeftOutlined style={{ color: "#fff" }} />
                            </ButtonAnt>
                            <p style={{color:"#fff"}}>{currentPage} de {Math.ceil(listSize / pagination.pageSize)}</p>
                            <ButtonAnt
                                disabled={currentPage === Math.ceil(listSize / pagination.pageSize)}
                                style={{ background: '#1a1a1a', border: 'none' }}
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                <RightOutlined style={{ color: "#fff" }} />
                            </ButtonAnt>
                        </div>
                    </>
                }
                {valueTab === 1 &&
                    <>
                          <TableContainer component={Paper} style={{ borderRadius: 13, background: "rgb(14, 14, 14)"}}>
                            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                <TableHead>
                                    <TableRowStyled>
                                        <TableCell align="center"  className={classes.typography}>Usuario</TableCell>
                                        <TableCell align="center" className={classes.typography}>Nombre</TableCell>
                                        <TableCell align="left"  className={classes.typography}>Email</TableCell>
                                        <TableCell align="left"  className={classes.typography}>Fecha</TableCell>
                                        <TableCell align="left"  className={classes.typography}>Banco</TableCell>
                                        <TableCell align="center"  className={classes.typography}>Retiro</TableCell>
                                        <TableCell align="center"  className={classes.typography}>No. Tarjeta</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRowStyled>
                                </TableHead>
                                <TableBody>
                                    {withdrawals.map((doc, index) => (
                                        <TableRowStyled
                                            key={doc.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {doc.status === "waiting" &&
                                                <TableCell component="th" scope="row" align="center"  className={classes.typography}>{doc.userName}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell component="th" scope="row" align="center"  className={classes.typography}>{doc.userName}</TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell component="th" scope="row" align="center"  className={classes.typography}>{doc.name}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell component="th" scope="row" align="center"  className={classes.typography}>{doc.name}</TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="left"  className={classes.typography}>{doc.email}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell align="left"  className={classes.typography}>{doc.email}</TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="left" className={classes.typography}>
                                                    {new Date(doc.withdrawalDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                                                </TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell align="left"  className={classes.typography}>
                                                    {new Date(doc.withdrawalDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                                                </TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="left"  className={classes.typography}>{doc.bank}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell align="left"  className={classes.typography}>{doc.bank}</TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="center"  className={classes.typography}>$ {doc.moneyAmount}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell align="center"  className={classes.typography}>$ {doc.moneyAmount}</TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="center"  className={classes.typography}>{doc.cardNumber}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell align="center"  className={classes.typography}>{doc.cardNumber}</TableCell>}

                                            <TableCell align="right">
                                                {doc.status === "waiting" &&
                                                    <Button variant="outlined" color="primary" size="small"
                                                        onClick={(e) => HandleWithdrawalDeclined(doc.id, doc.moneyAmount)}
                                                        className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 18px",
                                                            fontSize: "12px"
                                                        }}>
                                                        Declinado
                                                    </Button>}
                                                {doc.status === "declined" &&
                                                    <Button variant="contained" color="error" size="small"
                                                    className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 18px",
                                                            fontSize: "12px"
                                                        }}>
                                                        Declinado
                                                    </Button>}
                                                {doc.status === "approved" &&
                                                    <Button variant="contained" color="error" size="small" disabled
                                                    className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 18px",
                                                            fontSize: "12px"
                                                        }}>
                                                        Declinado
                                                    </Button>}
                                            </TableCell>

                                            <TableCell align="left">
                                                {doc.status === "waiting" &&
                                                    <Button variant="outlined" color="primary" size="small" onClick={(e) => HandleWithdrawalApproved(doc.id)}
                                                    className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 24px",
                                                            fontSize: "12px"
                                                        }}>
                                                        Aprobado
                                                    </Button>}
                                                {doc.status === "declined" &&
                                                    <Button variant="contained" color="error" size="small" disabled
                                                    className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 24px",
                                                            fontSize: "12px"
                                                        }}>
                                                        Aprobado
                                                    </Button>}
                                                {doc.status === "approved" &&
                                                    <Button variant="contained" color="success" size="small"
                                                    className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 24px",
                                                            fontSize: "12px"
                                                        }}>
                                                        Aprobado
                                                    </Button>}
                                            </TableCell>

                                        </TableRowStyled>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                            <ButtonAnt
                                disabled={currentPageWithdrawals === 1}
                                style={{ background: '#1a1a1a', border: 'none' }}
                                onClick={() => handlePageChangeWithdrawals(currentPageWithdrawals - 1)}>
                                <LeftOutlined style={{ color: "#fff" }} />
                            </ButtonAnt>
                            <p style={{color:"#fff"}}>{currentPageWithdrawals} de {Math.ceil(listSizeWithdrawals / paginationWithdrawals.pageSize)}</p>
                            <ButtonAnt
                                disabled={currentPageWithdrawals === Math.ceil(listSizeWithdrawals / paginationWithdrawals.pageSize)}
                                style={{ background: '#1a1a1a', border: 'none' }}
                                onClick={() => handlePageChangeWithdrawals(currentPageWithdrawals + 1)}
                            >
                                <RightOutlined style={{ color: "#fff" }} />
                            </ButtonAnt>
                        </div>
                    </>
                }

                {valueTab === 0 &&
                    <>
                        <Typography component="h1" variant="h6" color={'rgb(56, 161, 105)'} align="left" sx={{ mt: 4, mb: 1 }}>
                            Movimientos revisados
                        </Typography>
                        <TableContainer component={Paper} style={{ borderRadius: 13, background: "rgb(14, 14, 14)"}}>
                            <Table sx={{ minWidth: 250, }} aria-label="simple table">
                                <TableHead>
                                    <TableRowStyled>
                                        <TableCell  className={classes.typography}>Fecha</TableCell>
                                        <TableCell align="center"  className={classes.typography}>Usuario</TableCell>
                                        <TableCell align="right"  className={classes.typography}>Estatus</TableCell>
                                        <TableCell align="center"  className={classes.typography}>Aprobado por</TableCell>
                                        <TableCell align="right"  className={classes.typography}>Cantidad</TableCell>
                                        <TableCell align="right"  className={classes.typography}>Metodo</TableCell>
                                        <TableCell align="center"  className={classes.typography}>Voucher</TableCell>
                                    </TableRowStyled>
                                </TableHead>
                                <TableBody>
                                    {approvedDeposits.map((doc, index) => (
                                        <TableRowStyled
                                            key={doc.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row"  className={classes.typography}>
                                                {new Date(doc.depositDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                                            </TableCell>
                                            <TableCell align="center"  className={classes.typography}>{doc.userName}</TableCell>
                                            {doc.status === "approved" &&
                                                <TableCell align="right" className={classes.typography} style={{ color: "#2E7D32" }}>Aprobado</TableCell>
                                            }
                                            {doc.status === "denied" &&
                                                <TableCell align="right"  className={classes.typography} style={{ color: "#D32F2F" }}>Rechazado</TableCell>
                                            }
                                            {doc.status === "cancelado" &&
                                                <TableCell align="right"  className={classes.typography} style={{ color: "#D32F2F" }}>Cancelado</TableCell>
                                            }
                                            <TableCell align="center"  className={classes.typography}>{doc.authorizedUserName}</TableCell>
                                            <TableCell align="right"  className={classes.typography}>+ ${doc.amount}</TableCell>
                                            <TableCell align="right"  className={classes.typography}>{doc.payMethod}</TableCell>
                                            {doc.status === "approved" &&
                                                <TableCell align="center" >
                                                    <InsertDriveFileOutlinedIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: '#EA9F26', marginTop: "4px" }}
                                                        onClick={(e) => HandleVoucher(doc.voucher)} />
                                                </TableCell>}
                                            {doc.status === "denied" &&
                                                <TableCell align="center">
                                                    <InsertDriveFileOutlinedIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: 'gray', marginTop: "4px" }}
                                                        onClick={(e) => HandleVoucher(doc.voucher)} />
                                                </TableCell>}
                                            {doc.status === "cancelado" &&
                                                <TableCell align="center">
                                                    <InsertDriveFileOutlinedIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: 'gray', marginTop: "4px" }}
                                                        onClick={(e) => HandleVoucher(doc.voucher)} />
                                                </TableCell>}
                                        </TableRowStyled>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                            <ButtonAnt
                                disabled={currentPageApproved === 1}
                                style={{ background: '#1a1a1a', border: 'none' }}
                                onClick={() => handlePageChangeApprovedReviews(currentPageApproved - 1)}>
                                <LeftOutlined style={{ color: "#fff" }} />
                            </ButtonAnt>
                            <p style={{color:"#fff"}}>{currentPageApproved} de {Math.ceil(listSizeReviews / paginationApproved.pageSize)}</p>
                            <ButtonAnt
                                disabled={currentPageApproved === Math.ceil(listSizeReviews / paginationApproved.pageSize)}
                                style={{ background: '#1a1a1a', border: 'none' }}
                                onClick={() => handlePageChangeApprovedReviews(currentPageApproved + 1)}
                            >
                                <RightOutlined style={{ color: "#fff" }} />
                            </ButtonAnt>
                        </div>
                    </>}

                {valueTab === 1 &&
                    <>
                        <Typography component="h1" variant="h6" color={'rgb(56, 161, 105)'} align="left" sx={{ mt: 4, mb: 1 }}>
                            Movimientos revisados
                        </Typography>
                        <TableContainer component={Paper} style={{ borderRadius: 13, background: "rgb(14, 14, 14)"}}>
                            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                <TableHead>
                                    <TableRowStyled>
                                        <TableCell align="center" className={classes.typography}>Usuario</TableCell>
                                        <TableCell align="center" className={classes.typography}>Nombre</TableCell>
                                        <TableCell align="left" className={classes.typography}>Email</TableCell>
                                        <TableCell align="left" className={classes.typography}>Fecha</TableCell>
                                        <TableCell align="left" className={classes.typography}>Banco</TableCell>
                                        <TableCell align="center" className={classes.typography}>Retiro</TableCell>
                                        <TableCell align="center" className={classes.typography}>No. Tarjeta</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRowStyled>
                                </TableHead>
                                <TableBody>
                                    {withdrawalsReview.map((doc, index) => (
                                        <TableRowStyled
                                            key={doc.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {doc.status === "waiting" &&
                                                <TableCell component="th" scope="row" align="center" className={classes.typography}>{doc.userName}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell component="th" scope="row" align="center" className={classes.typography}>{doc.userName}</TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell component="th" scope="row" align="center" className={classes.typography}>{doc.name}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell component="th" scope="row" align="center" className={classes.typography}>{doc.name}</TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="left" className={classes.typography}>{doc.email}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell align="left"className={classes.typography}>{doc.email}</TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="left"className={classes.typography}>
                                                    {new Date(doc.withdrawalDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                                                </TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell align="left" className={classes.typography}>
                                                    {new Date(doc.withdrawalDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                                                </TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="left"className={classes.typography}>{doc.bank}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell align="left" className={classes.typography}>{doc.bank}</TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="center" className={classes.typography}>$ {doc.moneyAmount}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell align="center" className={classes.typography}>$ {doc.moneyAmount}</TableCell>}

                                            {doc.status === "waiting" &&
                                                <TableCell align="center" className={classes.typography}>{doc.cardNumber}</TableCell>}
                                            {doc.status !== "waiting" &&
                                                <TableCell align="center" className={classes.typography}>{doc.cardNumber}</TableCell>}

                                            <TableCell align="right">
                                                {doc.status === "waiting" &&
                                                    <Button variant="outlined" color="primary" size="small"
                                                        onClick={(e) => HandleWithdrawalDeclined(doc.id, doc.moneyAmount)}
                                                        className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 18px",
                                                            fontSize: "12px"
                                                        }}>
                                                        Declinado
                                                    </Button>}
                                                {doc.status === "declined" &&
                                                    <Button variant="contained" color="error" size="small"
                                                    className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 18px",
                                                            fontSize: "12px"
                                                        }}>
                                                        Declinado
                                                    </Button>}
                                                {doc.status === "approved" &&
                                                    <Button variant="contained" color="error" size="small" disabled
                                                    className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 18px",
                                                            fontSize: "12px",
                                                            color:"rgb(255 255 255 / 11%)"
                                                        }}>
                                                        Declinado
                                                    </Button>}
                                            </TableCell>

                                            <TableCell align="left">
                                                {doc.status === "waiting" &&
                                                    <Button variant="outlined" color="primary" size="small" onClick={(e) => HandleWithdrawalApproved(doc.id)}
                                                    className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 24px",
                                                            fontSize: "12px"
                                                        }}>
                                                        Aprobado
                                                    </Button>}
                                                {doc.status === "declined" &&
                                                    <Button variant="contained" color="error" size="small" disabled
                                                    className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 24px",
                                                            fontSize: "12px",
                                                            color:"rgb(255 255 255 / 11%)"
                                                        }}>
                                                        Aprobado
                                                    </Button>}
                                                {doc.status === "approved" &&
                                                    <Button variant="contained" color="success" size="small"
                                                    className={classes.typography}
                                                        style={{
                                                            borderRadius: 35,
                                                            padding: "8px 24px",
                                                            fontSize: "12px"
                                                        }}>
                                                        Aprobado
                                                    </Button>}
                                            </TableCell>

                                        </TableRowStyled>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                            <ButtonAnt
                                disabled={currentPageWithdrawalsReview === 1}
                                style={{ background: '#1a1a1a', border: 'none' }}
                                onClick={() => handlePageChangeWithdrawalsReview(currentPageWithdrawalsReview - 1)}>
                                <LeftOutlined style={{ color: "#fff" }} />
                            </ButtonAnt>
                            <p style={{color:"#fff"}}>{currentPageWithdrawalsReview} de {Math.ceil(listSizeWithdrawalsReview / paginationWithdrawalsReview.pageSize)}</p>
                            <ButtonAnt
                                disabled={currentPageWithdrawalsReview === Math.ceil(listSizeWithdrawalsReview / paginationWithdrawalsReview.pageSize)}
                                style={{ background: '#1a1a1a', border: 'none' }}
                                onClick={() => handlePageChangeWithdrawalsReview(currentPageWithdrawalsReview + 1)}
                            >
                                <RightOutlined style={{ color: "#fff" }} />
                            </ButtonAnt>
                        </div>
                    </>}
        
            </ContainerGeneral>
        </>
    );
};
export default ManageDeposits;