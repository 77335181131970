import React, { useEffect } from 'react';
import EventWeb from './Event';
import EventMobile from './EventMobile';
import { useUserAuth } from '../../context/UserAuthContext';

const Event = () => {
  const { isMobile } = useUserAuth();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      {isMobile ? <EventMobile /> : <EventWeb />}
    </div>
  );
}

export default Event;