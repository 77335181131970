import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { useUserAuth } from "../context/UserAuthContext";
import { MuiTelInput } from 'mui-tel-input'
import { styled } from '@mui/material/styles';
import './Login.css'
import { query, collection, where, getDocs, doc, updateDoc, setDoc } from "firebase/firestore";
import { firestore, db } from '../firebase'
import { v4 as uuidv4 } from "uuid";

const CssTextFieldPhone = styled(MuiTelInput)({
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ffffff',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
  },
  '& .MuiInputAdornment-root': {
    color: '#ffffff'
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
    },
  },
});

const CssTextFiel = styled(TextField)({
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
    borderColor: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#ffffff',
  },
});

function PhoneLogin() {
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const { setUpRecaptcha } = useUserAuth();
  const { createUserData } = useUserAuth();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [countDown, setCountDown] = useState(60);
  const timerId = useRef();
  const [blockResendBttn, setBlockResendBttn] = useState(false);
  const id = uuidv4();

  //DB data
  const [userName, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  function refreshPage() {
    window.location.reload(false);
  }

  const getUserDataAttempss = async (e) => {
    e.preventDefault();
    setError("")
    const queryPastSports = query(
      collection(db, "LoginAttempts"), where("phoneNumber", "==", number)
    );
    const querySnapshot = await getDocs(queryPastSports);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
  
    if (items.length > 0) {
      const userRef = doc(firestore, "LoginAttempts", items[0].id);
      const fechaInicio = new Date(new Date(items[0].loginPhoneAttemptsDate.seconds * 1000 + items[0].loginPhoneAttemptsDate.nanoseconds / 1000000)).getTime();
      const fechaFin = new Date().getTime();
      const diff = fechaFin - fechaInicio;
      if (items[0].loginPhoneAttempts >= 3) {
        if (diff / (1000 * 60 * 60 * 24) >= 30) {
          await updateDoc(userRef, {
            loginPhoneAttemptsDate: new Date(),
            loginPhoneAttempts: 1
          });
          getOtp(e)
        } else {
          setError("Ha superado el límite de inicio de sesión mediante número telefónico, intenta iniciar con tu correo electrónico");
        }
      } else {
        let newData = { loginPhoneAttempts: items[0].loginPhoneAttempts + 1}
        if (!items[0].loginPhoneAttemptsDate) {
          newData.loginPhoneAttemptsDate = new Date();
        }
        await updateDoc(userRef, newData);
        getOtp(e)
      }
    } else {
      const newEvent = {
        phoneNumber: number,
        loginPhoneAttempts: 1,
        loginPhoneAttemptsDate: new Date()
      };
      try {
        await setDoc(doc(db, "LoginAttempts", id), newEvent);
      } catch (err) {
      }
      getOtp(e)
    }
  }


  const getOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (number === "" || number === undefined) {
      return setError("Ingresa tu número!");
    }


    try {
      const response = await setUpRecaptcha(number);
      setResult(response);
      setPhoneNumber(number);
      setName(number.toString());
      setFlag(true);
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-phone-number":
          setError("¡Número de teléfono no valido, compruebalo de nuevo!");
          break;
        default:
          window.location.reload(false);
          setError("Error inesperado");
      }
    }
    handleOpen();
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) return;
    try {
      const { user } = await result.confirm(otp);
      const currentDate = new Date();
      const newUser = {
        userName,
        email: "",
        balance: 0,
        address: "",
        bankName: "",
        cardNumber: 0,
        city: "",
        clave: "",
        country: "",
        joinDay: currentDate,
        lastLogin: currentDate,
        phoneNumber,
        state: "",
        status: true,
        type: "",
        zipCode: 0,
        totalBets: 0,
        ganancias: 0,
        perdidas: 0
      };
      await createUserData(user, newUser);
      navigate("/homeuser");
      refreshPage();
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-verification-code":
          setError("¡Verifica tu código!");
          break;
        default:
          window.location.reload(false);
          setError("Error inesperado");
      }
    }
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    setError("");
    setBlockResendBttn(true);
    try {
      const response = await setUpRecaptcha(number);
      setResult(response);
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-phone-number":
          setError("¡Número de teléfono no valido, compruebalo de nuevo!");
          break;
        default:
          //window.location.reload(false);
          setError("Error inesperado");
      }
    }
  };



  //Timer resend otp
  useEffect(() => {
    if (open) {
      timerId.current = setInterval(() => {
        setCountDown(prev => prev - 1);
      }, 1000)
      return () => clearInterval(timerId.current);
    }
  }, [open]);

  useEffect(() => {
    if (countDown <= 0) {
      clearInterval(timerId.current);
      handleClose();
    }
  }, [countDown]);

  return (
    <>
      <div className="container-login-image">
        <Typography component="h1" variant="h4" color={'#FFFFFF'} align="center" sx={{ mt: 10, mb: 3 }} style={{ fontStyle: "italic", fontWeight: "bolder" }}>
          Iniciar sesión
        </Typography>
      </div>
      <div style={{ backgroundColor: "#2b2b2b" }}>
        <Container component="main" maxWidth="xs" style={{ backgroundColor: "#2b2b2b" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Box component="form" onSubmit={getUserDataAttempss} noValidate sx={{ mt: 3, width: 1 }}
              style={{ display: !flag ? "block" : "none", backgroundColor: "#2b2b2b" }}>
              {error && <Alert severity="error" sx={{ mt: 0, mb: 4 }}>{error}</Alert>}
              <Typography component="h2" variant="h6" borderColor="#ffffff" color={'#FFFFFF'} align="left" sx={{ mb: 2 }} >
                Número teléfonico
              </Typography>
              <CssTextFieldPhone sx={{ mt: 1 }} continents={["NA"]} forceCallingCode defaultCountry="MX" flagSize="small" value={number} onChange={setNumber} fullWidth autoFocus
                style={{ borderColor: "#ffffff" }} />
              &nbsp;
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 3, mb: 8 }}
                style={{
                  color: "#FFFFFF",
                  borderRadius: 28,
                  backgroundColor: "#781113",
                  padding: "10px 36px",
                  fontSize: "14px"
                }}
              >
                Iniciar sesión
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 5 }}
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#b35426",
                  borderRadius: 28,
                  padding: "10px 36px",
                  fontSize: "11px"
                }}
                onClick={(e) => navigate("/login")}
              >
                Iniciar sesión con correo electrónico
              </Button>
              <Button
                fullWidth
                variant="contained"
                color={'primary'}
                sx={{ mt: 2, mb: 8 }}
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#b35426",
                  borderRadius: 28,
                  padding: "8px 36px",
                  fontSize: "14px"
                }}
                onClick={(e) => navigate("/")}
              >
                Regresar
              </Button>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={12} container direction="column" alignItems="center" justifyContent="center">
                  <Typography style={{ textAlign: "center", color: "#ffffff" }}>
                    ¿No estás registrado?
                  </Typography>
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color={'secondary'}
                size={'small'}
                sx={{ mt: 2, mb: 8 }}
                style={{
                  color: "#000000",
                  backgroundColor: "#878484",
                  borderRadius: 28,
                  padding: "10px 36px",
                  fontSize: "14px"
                }}
                onClick={(e) => navigate("/phonesignup")}
              >
                Registrate
              </Button>
              <div id="recaptcha-container"></div>
            </Box>
            <Box component="form" onSubmit={verifyOtp} noValidate sx={{ mt: 28 }}
              style={{ display: flag ? "block" : "none" }}>
              {error && <Alert severity="error">{error}</Alert>}
              <Typography sx={{ mb: 2 }} style={{ textAlign: "center", color: "#ffffff" }}>
                Ingresa el código de verificación que enviamos a tu teléfono {number}
              </Typography>
              <CssTextFiel
                margin="normal"
                required
                fullWidth
                name="otp"
                label="Código de verificación"
                type="otp"
                id="otp"
                onChange={(e) => setOtp(e.target.value)}
              />
              {open ?
                <Typography sx={{ mt: 2 }} textAlign="center" component="h1" variant="h5" align="center" style={{ fontSize: "14px", color: '#ffffff' }}>
                  Reenviar código : {countDown}
                </Typography> :
                <>
                  {!blockResendBttn ?
                    <Button
                      variant="outlined"
                      fullWidth
                      size="small"
                      sx={{ mt: 3, mb: 3 }}
                      style={{
                        borderRadius: 22,
                        backgroundColor: "#b35426",
                        padding: "10px 36px",
                        fontSize: "12px"
                      }}
                      onClick={(e) => resendOtp(e)}
                    >
                      Reenviar código
                    </Button> :
                    <Button
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled
                      sx={{ mt: 3, mb: 3 }}
                      style={{
                        borderRadius: 22,
                        backgroundColor: "#b35426",
                        padding: "10px 36px",
                        fontSize: "12px",
                        color: "#ffffff"
                      }}
                    >
                      Código reenviado
                    </Button>
                  }
                </>
              }
              &nbsp;
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 4 }}
                style={{
                  color: "#FFFFFF",
                  borderRadius: 28,
                  backgroundColor: "#781113",
                  padding: "10px 36px",
                  fontSize: "14px"
                }}
              >
                Continuar
              </Button>
              <Button
                variant="contained"
                fullWidth
                size="small"
                color="primary"
                sx={{ mt: 1, mb: 5 }}
                style={{
                  borderRadius: 22,
                  backgroundColor: "#b35426",
                  padding: "10px 36px",
                  fontSize: "12px"
                }}
                onClick={(e) => refreshPage(e)}
              >
                Regresar
              </Button>
              <div id="recaptcha-container-resend" />
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default PhoneLogin;