import React, { useState, useEffect, useRef } from "react";
import { firestore } from "../firebase";
import { collection, query, Timestamp, getDocs, where } from "firebase/firestore";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { format, addDays } from 'date-fns';
import Loading from "./Loading";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from "react-date-range";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as rdrLocales from 'react-date-range/dist/locale';
import style from "styled-components";
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  }
})

const TableRowStyled = styled(TableRow)({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #1F1F1F',
    background: "#1A1A1A",
  },
});

const ContainerGeneral = style.div`
    padding: 2em 5em 2em;
    background: rgb(14, 14, 14);
    min-height:800px;

@media screen and (min-width: 0px) and (max-width: 1000px) {
        padding: 1em;
         min-height:500px;
}
`;

const ReportFight = () => {
  const [fights, setFights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const refOne = useRef(null)
  
  const classes = useStyles();

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ])

  const onChange = (values) => {
    if (values) {
      const startDate = Timestamp.fromDate(values[0].startDate).toDate()
      const endDate = Timestamp.fromDate(values[0].endDate).toDate()
      getBetsEarningsByDay(startDate, endDate)
      setRange(values)
    }
  }

  const openCalendar = () => {
    if (openDateRange === false) {
      setOpenDateRange(true)
    }
  }

  const startAt = (date) => {
    const parsed = format(date, 'yyyy-MM-dd 00:00:00.000');
    return new Date(parsed);
  };

  const endAt = (date) => {
    const parsed = format(date, 'yyyy-MM-dd 23:59:00.000');
    return new Date(parsed);
  };

  const getBetsEarningsByDay = async (startDateRange, endDateRange) => {
    setLoading(true);
    setOpenDateRange(false)
    const startDate = startAt(startDateRange);
    const endDate = endAt(endDateRange);
    const fightsCollectionRef = collection(firestore, "Fights");
    const queryFights = query(fightsCollectionRef, where('date', '>=', startDate), where('date', '<=', endDate));
    let dayFights = [];
    const querySnapshot = await getDocs(queryFights);
    querySnapshot.forEach(async (doc) => {
      const fight = { ...doc.data(), id: doc.id };
      const date = fight.date.toDate().toLocaleDateString("es-MX");
      dayFights.push({ ...fight, fecha: date });
    });
    for (let i = 0; i < dayFights.length; i++) {
      let totalRedBets = 0;
      let totalGreenBets = 0;
      if (dayFights[i].winner === "Empate") {
        dayFights[i].greenBets = totalGreenBets;
        dayFights[i].redBets = totalRedBets;
        dayFights[i].earnings = 0;
      } else {
        const betsRef = collection(firestore, "Bets");
        const queryBets = query(betsRef, where("uidFight", "==", dayFights[i].id));
        const querySnapshotB = await getDocs(queryBets);
        querySnapshotB.forEach((doc) => {
          const currentBet = doc.data();
          if (currentBet.open === false && currentBet.color === "Rojo") {
            totalRedBets = totalRedBets + currentBet.bet;
          }
          if (currentBet.open === false && currentBet.color === "Verde") {
            totalGreenBets = totalGreenBets + currentBet.bet;
          }
        })
        dayFights[i].greenBets = totalGreenBets.toFixed(2);
        dayFights[i].redBets = totalRedBets.toFixed(2);
        dayFights[i].earnings = ((totalGreenBets + totalRedBets) * 0.05).toFixed(2);
      }
    }
    dayFights.sort((a, b) => a.date > b.date);
    setFights(dayFights);
    setLoading(false);
    setOpenDateRange(false)
  }

  useEffect(() => {
    const currentDate = new Date();
    getBetsEarningsByDay(currentDate, currentDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContainerGeneral component="main" maxWidth="100%" style={{ background: "rgb(14, 14, 14)" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="body1" color="white"
            style={{
              borderRadius: 8,
              backgroundColor: "#781113",
              padding: "10px 18px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center"
            }}
            onClick={(e) => openCalendar()}>
            <CalendarMonthIcon /><p style={{ marginBottom: 0 }}>Seleccionar Fecha</p>
            <div className="calendarWrap">
              <div ref={refOne}>
                {openDateRange &&
                  <DateRange
                    locale={rdrLocales.es}
                    onChange={item => onChange([item.selection])}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    months={1}
                    direction="horizontal"
                    className="calendarElement"
                  />
                }
              </div>
            </div>
          </Typography>
        </div>
        {loading ? <Loading /> :
          <TableContainer component={Paper} style={{ borderRadius: 13, background: "rgb(14, 14, 14)", marginTop:"2em" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableRowStyled>
                  {/* <TableCell align="center">Fecha</TableCell> */}
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Número de pelea</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Nombre de la Pelea</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Fecha</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Total apuesta verde</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Total apuesta roja</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Ganador</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Ganancia</TableCell>
                </TableRowStyled>
              <TableBody>
                {fights.map((doc, index) => (
                  <TableRowStyled

                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {/* <TableCell align="center">{doc.date}</TableCell> */}
                    <TableCell align="center" className={classes.typography}>{doc.fightName}</TableCell>
                    <TableCell align="center" className={classes.typography}>{doc.fightName}</TableCell>
                    <TableCell align="center" className={classes.typography}>{doc.fecha}</TableCell>
                    <TableCell align="center" className={classes.typography}>{doc.greenBets}</TableCell>
                    <TableCell align="center" className={classes.typography}>{doc.redBets}</TableCell>
                    <TableCell align="center" className={classes.typography}>{doc.winner}</TableCell>
                    <TableCell align="center" className={classes.typography}>{doc.earnings}</TableCell>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </ContainerGeneral  >
    </>
  )
}

export default ReportFight;
