import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import Typography from '@mui/material/Typography';
import { useLocalStorage } from "../useLocalStorage";
import { firestore } from "../firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { Table, Button, Input, Space } from "antd";
import { SearchOutlined, FormOutlined, FileExcelOutlined } from "@ant-design/icons";
import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import './AllUsersPanel.css';
import Box from '@mui/material/Box';
import style from "styled-components";
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  }
})

const ContainerGeneral = style.div`
    padding: 1em 5em 2em;
    background: rgb(14, 14, 14);

@media screen and (min-width: 0px) and (max-width: 1000px) {
        padding: 1em;
}
`;

const CssTable = styled(Table)({
  '& .ant-table': {
    borderBottom: '1px solid #1F1F1F',
    borderRadius: "13px",
    background: "rgb(20, 20, 20)",
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  },
  '& .ant-table-thead > tr > th': {
    fontFamily: "Outfit",
    fontSize: "16px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff",
    background: "#1A1A1A",
    borderBottom: "1px solid #1F1F1F"
  },
  '& .ant-table-container table > thead > tr:first-child th:first-child': {
    borderTopLeftRadius: "13px",
  },
  '& .ant-table-container table > thead > tr:first-child th:last-child': {
    borderTopRightRadius: "13px",
  },
  '& .ant-table-tbody > tr > td': {
    borderBottom: "1px solid #1F1F1F"
  },
  '& .ant-table-tbody > tr.ant-table-row-hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover': {
    background: "#1A1A1A",
  },
  '& .ant-pagination-item-active': {
    background: "rgb(20, 20, 20)",
    borderColor: "#000",
  },

  '& .ant-pagination-item-active a': {
    color: "#fff"
  },

  '& .ant-pagination-item a': {
    color: "#fff"
  },

  '& .ant-pagination-item': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#1F1F1F",

  },

  '& .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#000",
  },

  '& .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#000",
  },

});

const AllUsersPanel = () => {
  const usersCollectionRef = collection(firestore, "Users");
  const [allUsers, setAllUsers] = useState([]);
  const [, setUidUserDetail] = useLocalStorage("uidUserDetail", "");
  const navigate = useNavigate();
  const inputSearch = useRef(null)
  const classes = useStyles();

  const handleOnExport = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(allUsers);

    utils.book_append_sheet(wb, ws, "usuarios");

    writeFile(wb, "usuarios.xlsx");
  };

  const handleMoreDetail = (userUid) => {
    setUidUserDetail(userUid);
    navigate("/userDetail");
  }

  const getAllUsers = async () => {
    const q = query(usersCollectionRef, orderBy("userName", "asc"),);

    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      const item = doc.data();
      items.push({
        ...item,
        id: doc.id,
        deposits: item?.deposits ? item.deposits : 0,
        depositsTotal: item?.depositsTotal ? item.depositsTotal : 0,
        ganancias: item.ganancias.toFixed(2),
        perdidas: item.perdidas.toFixed(2),
        balance: item.balance.toFixed(2)
      });
    });
    setAllUsers(items);
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'userName',
      key: 'userName',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              style={{ marginBottom: 8, display: 'block' }}
              placeholder=""
              value={selectedKeys[0]}
              ref={inputSearch}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
            ></Input>
            <Space>
              <Button
                key="btnFilter"
                type="primary"
                onClick={() => {
                  confirm();
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
              </Button>
              <Button
                key="btnCleaner"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: true });
                }}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if (record.userName != null) {
          return record.userName.toLowerCase().includes(value.toLowerCase());
        }
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => inputSearch.current?.focus(), 100);
        }
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              style={{ marginBottom: 8, display: 'block' }}
              placeholder=""
              value={selectedKeys[0]}
              ref={inputSearch}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
            ></Input>
            <Space>
              <Button
                key="btnFilter"
                type="primary"
                onClick={() => {
                  confirm();
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
              </Button>
              <Button
                key="btnCleaner"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: true });
                }}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if (record.email != null) {
          return record.email.toLowerCase().includes(value.toLowerCase());
        }
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => inputSearch.current?.focus(), 100);
        }
      },
    },
    {
      title: 'Apuestas',
      dataIndex: 'totalBets',
      key: 'totalBets',
      sorter: {
        compare: (a, b) => a.totalBets - b.totalBets,
        multiple: 6,
      },
    },
    {
      title: 'Total ingresado',
      dataIndex: 'depositsTotal',
      key: 'depositsTotal',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0 }}>${record.depositsTotal}</p>
          </>
        );
      },
      sorter: {
        compare: (a, b) => a.depositsTotal - b.depositsTotal,
        multiple: 4,
      },
    },
    {
      title: 'Ganancias',
      dataIndex: 'ganancias',
      key: 'ganancias',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0, color: '#50AF48' }}>${record.ganancias}</p>
          </>
        );
      },
      sorter: {
        compare: (a, b) => a.ganancias - b.ganancias,
        multiple: 3,
      },
    },
    {
      title: 'Perdidas',
      dataIndex: 'perdidas',
      key: 'perdidas',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0, color: '#DF2451' }}>${record.perdidas}</p>
          </>
        );
      },
      sorter: {
        compare: (a, b) => a.perdidas - b.perdidas,
        multiple: 2,
      },
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0, color: '#603B9A' }}>${record.balance}</p>
          </>
        );
      },
      sorter: {
        compare: (a, b) => a.balance - b.balance,
        multiple: 1,
      },
    },
    {
      title: "Ver más",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            onClick={() => handleMoreDetail(record.id)}
            type="link"
            icon={<FormOutlined />}
          >
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    getAllUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerGeneral component="main" maxWidth="100%" style={{ background: "rgb(14, 14, 14)" }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
       
          <div className="container-users-actions">
            <div style={{ flex: 2 }}>
              <Typography component="h1" variant="h4" className={classes.typography} style={{ fontSize: "2.125rem", textAlign: "left" }}>
                Usuarios
              </Typography>
            </div>
            <FileExcelOutlined
              className="btn-export-xlsx"
              onClick={handleOnExport}>
            </FileExcelOutlined>
          </div>
          <TableContainer component={Paper} style={{ borderRadius: 20, background: "rgb(14, 14, 14)", }}>
            <CssTable
              dataSource={allUsers}
              columns={columns}
              rowKey="id"
              scroll={{ x: '70vw' }}
              style={{ background: "rgb(14, 14, 14)" }}
            />
            </TableContainer>
      
      </Box>
    </ContainerGeneral>
  )

};

export default AllUsersPanel;
