import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import RollerSkatingIcon from '@mui/icons-material/RollerSkating';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import { onSnapshot, collection, query, where } from "firebase/firestore";
import React, { useEffect, useState } from 'react'
import { dbFights, db } from "../firebase";
import { Link } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        width: "60px",
        backgroundColor: "rgb(14, 14, 14)"
    },

    mainArea: {
        padding: theme.spacing(0),
        textAlign: "center",
        color: "#fff",
        height: "100%",
    },
    iconMenu: {
        marginBottom: "2em",
        textAlign: "center"
    }
}));

export default function NavBarLeft() {

    const [fights, setFights] = useState([]);
    const [sports, setSports] = useState([]);
    const { activeCategory, changeCategory } = useUserAuth();
    const currentCategory = activeCategory === null ? 1 : activeCategory;

    const classes = useStyles();
    const getFightsRealTime = async () => {
        const fightsCollectionRef = collection(dbFights, "Fights");
        const q = query(fightsCollectionRef, where("ended", "==", false));
        let items = [];
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().live) {
                    items.push({ ...doc.data(), id: doc.id });
                } else {
                    items.push({ ...doc.data(), id: doc.id });
                }
            });

            setFights(items);
        });

        return () => unsubscribe();
    }

    const getSportsRealTime = async () => {
        const sportsCollectionRef = collection(db, "Events");
        const q = query(sportsCollectionRef, where("ended", "==", false));
        let items = [];
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().live) {
                    items.push(doc.data().category);
                } else {
                    items.push(doc.data().category);
                }
            });


        });
        setSports(items)
        return () => unsubscribe();
    }

    useEffect(() => {
        getFightsRealTime();
        getSportsRealTime();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Box flexDirection="row" className={classes.root} style={{ position: "fixed", boxShadow: 'rgb(127 127 127 / 10%) 0px 8px 5px 0px', }}>
                <Box flexDirection="row" flexGrow={1}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        className={classes.mainArea}
                        flexGrow={1}
                    >
                        {fights.length > 0 &&
                            <Link to='/homeuser' >
                                <div className={classes.iconMenu} style={{ marginTop: "4em", fill: currentCategory === 1 ? "rgb(56, 161, 105)" : "#fff", }}
                                    onClick={() => changeCategory(1)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="35" height="35" stroke="#000" viewBox="0 0 364.6 364.6"><path d="m350 84-2-1 5 1c5 0 5-6 5-8 0-9-28-19-67-23h-8c-36 0-61 27-70 38l-2 2c-5 6-14 23-22 37l-9 16h-1c-5 0-26-3-42-13-11-6-17-20-24-36-5-10-10-21-17-32-12-19-29-28-37-32l-5-2c7-4 18-12 20-20l-1-3-2-1-12 4-2 1 1-2c3-4 5-6 5-8l-1-2h-2c-2 0-5 3-10 8l-4 4 1-3V2l-2-1c-3 0-6 5-9 11l-2 5-1-3c0-4-1-7-3-7s-3 3-5 9l-2 6-3-3-5-4h-1l-1 6-1 5-1 1-1-1-2-2-3 1c-2 2-1 7 0 17v6c0 4 2 7 4 8l-2 7 1 8 1 1 1-1 5-5v9l1 9c0 12 8 15 11 17l-3 6-8 14c-7 13-9 38-5 65 2 20 45 63 68 85a945 945 0 0 1 13 14 1166 1166 0 0 1-23 40l-4-1-4-2h-2l-2 1c0 1 0 2 5 5l-8 2-12 3c-8 3-8 3-8 5l2 1 6-1a90 90 0 0 1 17-1c-3 2-8 6-9 11v2h1l3-1 4-4a119 119 0 0 1 21-9l6 1 4 1 2-1-3-4c-1-1-4-4-4-6l3-7c3 0 6-1 10-4 4-2 4-3 4-4l-2-1-5 1h-3l13-20 8 1 11-2a601 601 0 0 1-4 54l-7-4-1 1 1 2 2 2h-7a104 104 0 0 0-21 2l-1 2 10 2a461 461 0 0 0 15 3c-6 2-11 6-13 8v2h1l2-1 7-3 17-3 7-3h1l5 1 5 2 2-1c0-1-1-4-8-12-1 0-2-3-2-7 3-1 6-3 9-7 2-3 3-4 2-5l-1-1-5 3-1 1-4 1 2-46 6-8c3-5 6-10 12-10h3l8 1h11c6 0 11 1 17 3l9 1c4 0 5-2 6-3 2-5-3-12-8-18l-13-14-4-3h8c10 0 21-1 32-4 7-2 8-4 12-10l2-3 3-5 2-1 2-1 5-1 11-3c2-2 4-2 6-2s4 0 6-3l1-5c1-3 1-4 3-5l3-2-1-5-1-5c-1-3 0-4 2-5l6-5c4-4 2-7 1-9v-2l13-22v-19c1-5 0-7-1-10l-2-8c0-5-4-7-6-8a317 317 0 0 1 9-2c8 0 20 6 31 10 9 5 18 9 24 9l4-1 3-4c1-5-7-13-11-18z"/></svg>
                                </div>
                            </Link>}
                        {sports.includes("Fútbol") &&
                            <Link to='/sports-user' key={2} state={{ category: 'Fútbol' }} >
                                <div className={classes.iconMenu} style={{ color: currentCategory === 2 ? "rgb(56, 161, 105)" : "#fff" }}
                                    onClick={() => changeCategory(2)}>
                                    <SportsSoccerIcon style={{ fontSize: "35px" }} />

                                </div>
                            </Link>}
                        {sports.includes("Frontón") &&
                            <Link to='/sports-user' key={3} state={{ category: 'Frontón' }} >
                                <div className={classes.iconMenu} style={{ color: currentCategory === 3 ? "rgb(56, 161, 105)" : "#fff" }}
                                    onClick={() => changeCategory(3)}>
                                    <SportsHandballIcon style={{ fontSize: "35px" }} />
                                </div>
                            </Link>}
                        {sports.includes("Carreras") &&
                            <Link to='/sports-user' key={5} state={{ category: 'Carreras' }} >
                                <div className={classes.iconMenu} style={{ color: currentCategory === 5 ? "rgb(56, 161, 105)" : "#fff" }}
                                    onClick={() => changeCategory(5)}>
                                    <SportsMotorsportsIcon style={{ fontSize: "35px" }} />
                                </div>
                            </Link>}
                        {sports.includes("Patinaje") &&
                            <Link to='/sports-user' key={6} state={{ category: 'Patinaje' }} >
                                <div className={classes.iconMenu} style={{ color: currentCategory === 6 ? "rgb(56, 161, 105)" : "#fff" }}
                                    onClick={() => changeCategory(6)}>
                                    <RollerSkatingIcon style={{ fontSize: "35px" }} />
                                </div>
                            </Link>}
                        {sports.includes("Tenis") &&
                            <Link to='/sports-user' key={7} state={{ category: 'Tenis' }} >
                                <div className={classes.iconMenu} style={{ color: currentCategory === 7 ? "rgb(56, 161, 105)" : "#fff" }}
                                    onClick={() => changeCategory(7)}>
                                    <SportsTennisIcon style={{ fontSize: "35px" }} />
                                </div>
                            </Link>}
                        {sports.includes("Golf") &&
                            <Link to='/sports-user' key={8} state={{ category: 'Golf' }} >
                                <div className={classes.iconMenu} style={{ color: currentCategory === 8 ? "rgb(56, 161, 105)" : "#fff" }}
                                    onClick={() => changeCategory(8)}>
                                    <GolfCourseIcon style={{ fontSize: "35px" }} />
                                </div>
                            </Link>}
                        {sports.includes("Voleibol") &&
                            <Link to='/sports-user' key={9} state={{ category: 'Voleibol' }} >
                                <div className={classes.iconMenu} style={{ color: currentCategory === 9 ? "rgb(56, 161, 105)" : "#fff" }}
                                    onClick={() => changeCategory(9)}>
                                    <SportsVolleyballIcon style={{ fontSize: "35px" }} />
                                </div>
                            </Link>}
                        {sports.includes("Básquetbol") &&
                            <Link to='/sports-user' key={10} state={{ category: 'Básquetbol' }} >
                                <div className={classes.iconMenu} style={{ color: currentCategory === 10 ? "rgb(56, 161, 105)" : "#fff" }}
                                    onClick={() => changeCategory(10)}>
                                    <SportsBasketballIcon style={{ fontSize: "35px" }} />
                                </div>
                            </Link>}
                        {sports.includes("Fútbol americano") &&
                            <Link to='/sports-user' key={11} state={{ category: 'Fútbol americano"' }} >
                                <div className={classes.iconMenu} style={{ color: currentCategory === 11 ? "rgb(56, 161, 105)" : "#fff" }}
                                    onClick={() => changeCategory(11)}>
                                    <SportsFootballIcon style={{ fontSize: "35px" }} />
                                </div>
                            </Link>}
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}
