import React from 'react'
import { Typography } from '@material-ui/core';
import { useUserAuth } from '../context/UserAuthContext';
import { Link } from "react-router-dom";
import './HomeUser.css'


const MenuCategory = ({ sizeScreen }) => {
  const { activeCategory, changeCategory } = useUserAuth();
  const currentCategory = activeCategory === null ? 1 : activeCategory;
  changeCategory(1)

  return (
    <div>
      {sizeScreen === "mobile" &&
        <div className="nav-bar-container-vertical">
          <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
            <Link to='/homeuser'>
              <Typography variant="h1" component="div" color="#fff" style={{ marginTop:"1em", fontSize: "12px", marginRight: "4em", letterSpacing: "2px", color: currentCategory === 1 ? "rgb(56, 161, 105)" : "#fff", }}
                onClick={() => changeCategory(1)}>
                <>PELEAS</><Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}></Link>
              </Typography>
            </Link>
            <Link to='/sports-user' state={{ category: 'Fútbol' }}>
              <Typography variant="h1" component="div" style={{ marginTop:"1em", fontSize: "12px", marginRight: "4em", letterSpacing: "2px", color: currentCategory === 2 ? "rgb(56, 161, 105)" : "#fff", }}
                onClick={() => changeCategory(2)}>
                <>DEPORTES</><Link to='/sports-user' key={2} state={{ category: 'Fútbol' }} ></Link>
              </Typography>
            </Link>
            <Link to='/homeuser' state={{ category: 'Fútbol' }}>
              <Typography variant="h1" component="div" color="#fff" style={{ marginTop:"1em", fontSize: "12px", letterSpacing: "2px", color: "#fff" }} >
                <>RULETA</><Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}></Link>
              </Typography>
            </Link>
          </div>
        </div>
      }
    </div>
  );
};

export default MenuCategory;