import Container from '@mui/material/Container';
import './TerminosCondiciones.css'
import LogoCompleto from "../Images/xtremo-logo 1.png"

const TerminosCondiciones = () => {
  return (
    <div className='container-terms-conditions'>
      <Container component="main" maxWidth="100%" style={{ backgroundColor: "#2b2b2b", paddingBottom: "2em" }} >
        <div className='container-terms'>
          <div className='container-text'>
            <div className='title-container'>
              <h3 style={{ color: "#ffffff" }}>Términos y Condiciones</h3>
            </div>
            <p className='style-text'>
              1. Estos Términos y Condiciones aplican a cualquier uso de nuestros servicios. Para artículos promocionales, pueden aplicar términos adicionales. Al utilizar nuestra aplicación, visitar el Sitio y/o registrarse y/o utilizar nuestros servicios, usted acepta y está obligado por:
            </p>
            <p className='style-text'>
              a. Estos Términos y Condiciones;
            </p>
            <p className='style-text'>
              b. Política de Privacidad;
            </p>
            <p className='style-text'>
              c. Las reglas que aplican a cada juego de casino pueden ser encontradas dentro de cada juego de casino, e incluye la ganancia máxima disponible para cada juego ("Reglas de Juego");
            </p>
            <p className='style-text'>
              d. Cualquier condición específica que se indique como aplicable (por ejemplo, si está ingresando a una promoción, los términos y condiciones de la promoción); y
            </p>
            <p className='style-text'>
              e. Todos los demás términos y condiciones, reglas o políticas que se relacionan con las promociones de servicios aplicables; bonificaciones, ofertas especiales o cualquier otro aspecto de su uso de los servicios, considerándose para todos los efectos aplicables que usted ha aceptado y comprendido los mismos.
            </p>
            <p className='style-text'>
              Todos los términos y condiciones, reglas y políticas a las que se hace referencia en el párrafo anterior se denominarán colectivamente, como los “Términos y Condiciones”.
            </p>
            <p className='style-text'>
              2. EL USO POR PARTE DE USTED DEL SITIO Y/O DE LOS SERVICIOS, IMPLICA QUE USTED ESTÁ DE ACUERDO CON ESTOS TÉRMINOS Y CONDICIONES, Y CONSTITUYE UN ACUERDO VINCULANTE DE PARTE DE USTED HACIA LOS MISMOS, INCLUYENDO CUALQUIER MODIFICACIÓN QUE HAGAMOS DE TIEMPO EN TIEMPO. Si no está de acuerdo con estos Términos y Condiciones, su única alternativa es abstenerse de acceder al Sitio y usar los Servicios.
            </p>
            <p className='style-text'>
              3. El Sitio brinda a los usuarios la oportunidad de ganar premios en especie (mercancía, experiencias) y en efectivo, a través de la colocación de apuestas en Juegos (los “Juegos”); o, cuando así sea el caso, mediante la adquisición de bonos en modalidad gratuita. Para poder entrar a los Juegos, los participantes deben crear una cuenta de usuario en el Sitio. Además de las reglas establecidas en estos Términos y Condiciones para los Juegos, cada Juego podrá sujetarse a reglas específicas, que se publicarán junto con el Juego de que se trate y estarán disponibles para consulta de los participantes. En caso de discrepancia entre estos Términos y Condiciones y las reglas específicas que pudieran publicarse para cada Juego, prevalecerán dichas reglas.
            </p>
            <p className='style-text'>
              4. Cuando usted registra una cuenta con nosotros, está aceptando y garantizando que:
            </p>
            <p className='style-text'>
              a. Tiene al menos 18 años. Nos reservamos el derecho de solicitar documentación para verificar la edad a cualquier solicitante o cliente en cualquier momento, y anularemos cualquier transacción realizada por menores de edad;
            </p>
            <p className='style-text'>
              b. Proporcionará información verdadera al abrir su cuenta, que incluirá datos tales como su fecha de nacimiento, junto con una identificación válida que lo respalde, su país de residencia, su domicilio actual, dirección de correo electrónico, número de teléfono personal, entre otros. Debe informarnos de cualquier cambio en estos detalles;
            </p>
            <p className='style-text'>
              c. Cuenta con la capacidad legal para entrar en un contrato vinculado, incluidos estos Términos y Condiciones y cualquier apuesta que usted realice;
            </p>
            <p className='style-text'>
              d. Está abriendo su cuenta únicamente para uso personal, y está actuando en su propio nombre y no como un agente en nombre de un tercero;
            </p>
            <p className='style-text'>
              e. No intentará vender ni de ninguna manera transferir el beneficio de su cuenta a terceros, ni adquirirá ni intentará adquirir una cuenta que se haya abierto a nombre de un tercero;
            </p>
            <p className='style-text'>
              f. No tiene prohibido, por ningún motivo, apostar con nosotros o utilizar nuestros servicios; y
            </p>
            <p className='style-text'>
              g. Actualmente no está sujeto a una autoexclusión de una cuenta del Sitio y ni nosotros ni ningún otro operador lo ha excluido de los juegos de azar.
            </p>
            <p className='style-text'>
              h. Debe mantener su información personal al día. Si no lo hace, es posible que sus apuestas o pagos se rechacen o se emitan de forma incorrecta.
            </p>
            <p className='style-text'>
              i. No puede transferir fondos de una cuenta del Sitio a otra bajo ninguna circunstancia.
            </p>
            <p className='style-text'>
              5. Si incumple con alguno de los términos del numeral 6 de estos Términos y Condiciones, podrá el Sitio proceder a:
            </p>
            <p className='style-text'>
              a. Podemos cancelar cualquier apuesta que haya realizado;
            </p>
            <p className='style-text'>
              b. No estaremos obligados a pagar ninguna ganancia que de lo contrario se hubiera pagado con respecto a cualquier apuesta que haya realizado; y
            </p>
            <p className='style-text'>
              c. Podemos referir el asunto a la policía, tutores o miembros de la familia, o cualquier otra autoridad reguladora apropiada.
            </p>
            <p className='style-text'>
              6. Los Servicios no se dirigen a, ni podrán ser utilizados por, personas menores de 18 (dieciocho) años. Usted debe tener al menos 18 (dieciocho) años cumplidos y tener capacidad legal para contratar y para poder acceder al Sitio y/o hacer uso de los Servicios. Al acceder al Sitio y usar los Servicios, usted manifiesta y garantiza que tiene la edad suficiente y la capacidad para obligarse a estos Términos y Condiciones. Nos reservamos el derecho de solicitar documentación para verificar la edad a cualquier solicitante o cliente en cualquier momento, y podemos anular cualquier transacción realizada por menores de edad.
            </p>
            <p className='style-text'>
              7. Usted acepta que tenemos derecho a verificar electrónicamente su información cuando abre una cuenta con nosotros. Al aceptar estos Términos y Condiciones y/o registrarse para utilizar la aplicación y/o el Sitio, usted acepta que tenemos derecho a realizar cualquiera y todas las verificaciones de identificación, crédito, fraude y otras verificaciones que sean necesarias y/o que sean requeridas por las leyes y regulaciones aplicables y/o por las autoridades reguladoras relevantes para el uso de la aplicación y/o del Sitio y nuestros productos en general. Usted acepta que nos proporcionará toda la información que necesitemos en relación con dichas verificaciones. Tendremos derecho a suspender o restringir su cuenta de cualquier manera apropiada, hasta completar esas verificaciones a nuestra satisfacción. Además de las verificaciones electrónicas, podemos realizar verificaciones manuales para confirmar el domicilio y la identidad. Para confirmar su identidad, puede que sea necesario que usted le envíe una copia de sus documentos de identidad al correo que nosotros le indiquemos en ese supuesto.
            </p>
            <p className='style-text'>
              8. Se requiere que cada cliente se identifique por su número de cuenta y contraseña en cada inicio de sesión. El cliente tiene la responsabilidad exclusiva de velar por su contraseña específica y número de cuenta, así como de no revelarla a ninguna persona.
            </p>
            <p className='style-text'>
              9. No seremos responsables de pérdidas derivadas del mal uso de su nombre de usuario o contraseña o de cualquier acceso no autorizado a su cuenta. Si ha perdido u olvidado alguno de los datos de su cuenta, contáctenos lo antes posible. Debe informarnos inmediatamente si sospecha que alguien está usando los datos de su cuenta indebidamente para que podamos suspender su cuenta y evitar cualquier acceso no autorizado.
            </p>
            <p className='style-text'>
              Es nuestra recomendación que utilice un dispositivo de almacenamiento seguro de contraseñas para guardar sus detalles de cuenta en lugar de usar la memoria de contraseña automática en su navegador.
            </p>
            <p className='style-text'>
              10. El uso de los Servicios pudiera estar prohibido o regulado en ciertos estados o países. Si utiliza los Servicios fuera del territorio mexicano, usted es responsable del cumplimiento de las leyes o regulación del estado o territorio desde el cual usted acceda al Sitio y/o utilice los Servicios. No podrán registrarse los residentes de aquellos países en los que se prohíbe participar por cualquier medio en Juegos con apuestas.
            </p>
            <p className='style-text'>
              11. Usted tiene la responsabilidad de cumplir con sus propias leyes locales, nacionales, federales, estatales u otras relacionadas con las apuestas o juegos antes de abrir una cuenta, realizar apuestas, o utilizar nuestros Servicios. Si se encuentra en un país donde está prohibido el uso de servicios de juegos de azar o de lotería, no debe (i) registrarse con nosotros para el servicio correspondiente; (ii) intentar utilizar ese servicio; o (iii) utilizar su tarjeta de pago u otro método de pago para realizar apuestas o juegos con nosotros por el servicio ilegal.
            </p>
            <p className='style-text'>
              12. Nos reservamos el derecho de suspender o desactivar cualquier cuenta de cualquier usuario en caso de sospechar que el usuario esté actuando en contravención de estos Términos y Condiciones y/o de cualquier legislación o regulación aplicable. De igual forma, las personas en incumplimiento a estos Términos y Condiciones no podrán tener derecho a cobrar cualquier ganancia y pueden cometer un fraude y ser sancionadas por dicho ilícito.
            </p>
            <p className='style-text'>
              A- Chat de servicio a clientes: Cualquier consulta/duda del usuario mediante chat debe expresarse respetuosamente. Ante cualquier falta del respeto se tomarán las medidas pertinentes (Advertencia y/o bloqueo definitivo del chat). Cualquier amedrentamiento (Imágenes indebidas) o imágenes que hagan alusión a ello, se procederá a suspender el acceso permanentemente del chat sin advertencia alguna. Al ser bloqueado, cualquier consulta/duda será atendida vía correo electrónico (misgallos.mx@gmail.com)
            </p>
            <p className='style-text'>
              13. No seremos responsables por cualquier daño o pérdida que Usted o cualquier tercero alegue o considere le fueron causados en conexión con su uso de los Servicios; incluyendo, pero sin limitarse a: aquellos que resulten de un uso inapropiado por parte del Usuario de los Servicios; de errores de comunicación entre el Usuario y el Sitio; o, de una interrupción de los Servicios imputable o no al Sitio.
            </p>
            <p className='style-text'>
              14. Usando nuestros servicios, dice saber que el acceso a las apuestas implica que no resulte ganador en los Juegos de que se traten, por lo que no podrá reclamar nada en contra de nosotros o de cualquiera de las compañías de nuestro grupo o de nuestros respectivos directores, funcionarios o empleados. De igual forma, nosotros no somos responsable de cualquier pérdida, daño, menoscabo o afectación en la que usted incurra por el uso o aceptación de algún premio.
            </p>
            <p className='style-text'>
              15. Se prohíbe usar un método de pago del que usted no sea el único titular o que pertenezca a un tercero (por ejemplo, no puede usar la tarjeta de débito de un miembro de la familia para hacer depósitos en su cuenta). Cualquier depósito de terceros será considerado inválido (y cualquier ganancia o premio que surja del depósito será anulada).
            </p>
            <p className='style-text'>
              16. Para obtener el derecho a participar en los Juegos, necesita depositar dinero que se constituirá en sus fondos. Sus fondos se mantendrán en una cuenta bancaria separada de todas nuestras otras cuentas comerciales, y se encontrarán en, todo momento, resguardados y disponibles para usted. Usted podrá disponer de sus fondos en las formas permitidas por el Sitio, participar en un Juego o Sorteo, solicitar su devolución cuando se trate de cantidades reembolsables, pago de premios, cruce de nuevas apuestas, etcétera
            </p>
            <p className='style-text'>
              17. En apego al artículo 10 del Reglamento de la Ley Federal de Juegos y Sorteos en vigor en los Estados Unidos Mexicanos, todas las transacciones efectuadas en el Sitio se realizarán en pesos mexicanos. Aceptamos diferentes métodos de pago, mismos que podrán variar según se encuentren disponibles de tiempo en tiempo en México. Los métodos de pago disponibles para usted se mostrarán en la aplicación o el Sitio en el momento de realizar su depósito. Solo se le permite usar métodos de pago que estén a su nombre. Los depósitos que realice deberán ser hechos desde una cuenta personal y no de una cuenta empresarial o corporativa. Si realiza depósitos usando métodos de pago respecto de los que no tiene derecho a usar (en los que no sea titular), podremos invalidarlo (y cualquier ganancia que obtenga con motivo de ese depósito se anulará). Esto significa que usted no tendrá derecho a ganancias sobre una apuesta colocada con estos depósitos.
            </p>
            <p className='style-text'>
              18. Se podrá anular sus ganancias cuando haya realizado depósitos con la intención de anular las apuestas utilizando el depósito que luego canceló. Si algún depósito no nos llega o lo cancela por usted o por un tercero, tendremos derecho a anular todos los bonos, las apuestas gratuitas y las ganancias acumuladas u otorgadas a su cuenta como resultado de dicho depósito, si creemos, a nuestra razonable discreción, que el depósito se realizó para anular las apuestas obtenidas utilizando el que canceló. En caso de contar con alguna duda, contacta soporte para más información
            </p>
            <p className='style-text'>
              19. Nosotros realizaremos constantemente chequeos de antilavado de dinero en sus depósitos. Estamos obligados por las regulaciones contra el lavado de dinero y como parte de nuestras políticas internas a realizar revisiones respecto a la fuente de los fondos depositados por los clientes. Si estas revisiones no nos brindan suficiente información sobre usted, podemos solicitarle más información (incluida su fuente de ingresos). Usted acepta proporcionar de inmediato esta información, cuando se le solicite. Nos reservamos el derecho, actuando razonablemente, de suspender o cancelar cualquier cuenta cuando no podamos asegurarnos de que la fuente de fondos sea legítima o cuando no se haya proporcionado información suficiente.
            </p>
            <p className='style-text'>
              20. Ningún fondo generará intereses sobre ninguno de los saldos de su cuenta. No concedemos crédito en ninguna circunstancia. Es su responsabilidad mantener fondos suficientes en su cuenta para realizar cualquier apuesta que desee hacer. No seremos responsables de ninguna apuesta perdida por no tener los fondos requeridos en su cuenta para hacer la apuesta. También reservamos el derecho de anular cualquier apuesta aceptada voluntariamente si su cuenta no tiene fondos suficientes para cubrir toda la apuesta y/o recuperar el monto de cualquier déficit. No somos una institución financiera y no se generan ni pagan intereses sobre los depósitos, ni se garantizan ganancias.
            </p>
            <p className='style-text'>
              21. EL USUARIO acepta el hecho de que XTREMO, descuente de la cantidad solicitada en su retiro en concepto de retención, el 1% de impuestos determinado por Legislación vigente en la República Mexicana, por la obtención de ingresos derivados de ganancias en el juego.
            </p>
            <p className='style-text'>
              En caso de cualquier error de retraso o fallo en el juego se considerará como fallo del sistema y se cancelarán las ganancias obtenidas.
            </p>
            <p className='style-text'>
              Solo estamos obligados a pagar las ganancias que se han ganado realmente en conformidad con nuestros Términos y Condiciones.
            </p>
            <p className='style-text'>
              22. En caso de detectar un comportamiento inusual o sospechoso de juego todas las ganancias serán retenidas.
            </p>
            <p className='style-text'>
              23. Puede retirar fondos de su cuenta si: (a) todos los pagos realizados en su cuenta han sido confirmados como aprobados y no se han devuelto, revocado o cancelado; (b) a nuestra discreción usted ha cumplido con los reglamentos y las normas contra el lavado de dinero u otras obligaciones impuestas para verificar su identidad. Tenga en cuenta también que, cuando le solicitamos información para verificar su identidad y/o fuente de fondos, cualquier demora en el suministro de esta información puede causar un retraso adicional en retirar fondos; (c) acepta que todas las transacciones pueden ser verificadas para prevenir el lavado de dinero y que cualquier transacción que usted realice y que consideremos sospechosa, puede ser informada a las autoridades correspondientes. Nos reservamos el derecho de rechazar y/o revertir el retiro de depósitos no utilizados hasta que la verificación de estos fondos se considere satisfactoria, incluso cuando los depósitos se hayan realizado y retirado de inmediato sin apostar; y (d) actualmente no haya una investigación en curso sobre:
            </p>
            <p className='style-text'>
              I. Una situación en la que exista evidencia de irregularidades en las apuestas, el precio haya sido manipulado, haya tenido lugar el arreglo o amaño de partidos, si se sospecha que un individuo o individuos actúan en forma conspirativa o en conjunto con respecto a una apuesta o;
            </p>
            <p className='style-text'>
              II. una situación en la que exista evidencia de que ha violado materialmente estos términos y condiciones, incluso por actuar de manera fraudulenta, ilegal o por participar en actividades inapropiadas (incluidas trampas, colusión o actividades delictivas).
            </p>
            <p className='style-text'>
              24. Es posible que nuestro equipo de seguridad se vea en la necesidad de cancelar retiros en espera del resultado de una investigación. Puede haber ocasiones en las que nuestro equipo de seguridad deba cancelar los retiros para llevar a cabo controles adicionales. Estos fondos se devolverán a su cuenta en espera de la investigación, pero si descubrimos alguna irregularidad durante nuestra investigación, nos reservamos el derecho de retener fondos. La responsabilidad de los fondos recae en el titular de la cuenta.
            </p>
            <p className='style-text'>
              25. Si desea retirar menos de lo que permite nuestro sistema en línea, deberá contactar con nosotros. Si la cantidad que desea retirar está por debajo de la cantidad aceptada a través del Sitio, que se le mostrará en el momento de la retirada, aún podrá retirar sus fondos, pero deberá comunicarse con nosotros.
            </p>
            <p className='style-text'>
              26. Si usted ha recibido cantidades por error, podremos solicitarle su devolución. Pueden existir circunstancias en las que se le paguen cantidades por error (por ejemplo, una errónea liquidación de una apuesta). Utilizaremos todos los esfuerzos razonables para detectar cualquier error e informarle de él cuanto antes. Sin embargo, si se le han acreditado cantidades que se pagaron por error, deberá resguardarlas por nosotros y devolverlas a nosotros inmediatamente si así se lo solicitamos. Si dichas cantidades se encuentran aún en su cuenta, podremos recabarlas directamente desde su cuenta.
            </p>
            <p className='style-text'>
              27. Usted es responsable de informar cualquier error con sus retiros. Estamos comprometidos para evitar errores, pero no podemos asumir ninguna responsabilidad u obligación por los errores u omisiones que usted realice cuando retire sus fondos. Si algún fondo se deposita o acredita incorrectamente, es su responsabilidad informarnos y nuestro objetivo será resolverlo.
            </p>
            <p className='style-text'>
              28. Los retiros se realizarán con el mismo método de pago que el depósito inicial. Si esto no es posible, lo contactaremos para hacer arreglos alternativos.
            </p>
            <p className='style-text'>
              29. El monto máximo de retiro por día es de $25,000 MXN únicamente.
            </p>
            <p className='style-text'>
              30. Debe notificarnos cualquier cambio en los detalles de su método de pago. Si no nos actualiza, su banco puede rechazar sus depósitos o apuestas. También debe informarnos si perdió o le robaron su tarjeta de crédito o débito o si ha sido víctima de un fraude de identidad en su cuenta de método de pago y dejaremos que se acepten más apuestas desde su cuenta hasta que nos notifique sobre otras instrucciones.
            </p>
            <p className='style-text'>
              31. Solo se permite una cuenta por persona. En caso de una multi-cuenta (esto quiere decir una cuenta que corresponda a la misma persona se cerrará automáticamente y permanecerá abierta solo la primera cuenta creada) en caso de 2 o más registros donde las cuentas tengan el mismo domicilio o misma dirección IP las cuentas duplicadas serán congeladas y no aplicarán para promociones. Cualquier abuso o mal uso de la cuenta, XTREMO se reserva el derecho de cancelar las ganancias, promociones y cerrar todas las cuentas involucradas.
            </p>
            <p className='style-text'>
              32. Las apuestas que usted realice en el Sitio, estarán sujetas a esto Términos y Condiciones. Habrá límites en la cantidad que puede apostar. Solo puedes apostar hasta el menor de la cantidad presente en su cuenta o cualquier límite establecido por nosotros.
            </p>
            <p className='style-text'>
              33. Debe familiarizarse con la forma en que se operan los Juegos. No seremos responsables bajo ninguna circunstancia si realiza una apuesta o juega un juego y no ha entendido completamente los términos o cómo se opera. Le recomendamos que se familiarice con la terminología estándar de apuestas y juegos. Si duda de cómo funciona un juego, comuníquese con nosotros.
            </p>
            <p className='style-text'>
              34. Una vez que las ganancias estén disponibles en su cuenta, podrá retirarlas. Todas las ganancias obtenidas se mantendrán en su cuenta. Estas estarán disponibles para ser retiradas, sujeto a estos Términos y Condiciones.
            </p>
            <p className='style-text'>
              35. Para recibir las promociones por depósito deberás ingresar el Código de Bono o seleccionar la promoción, en caso de no ingresar el código, señalar la promoción o ingresar el código incorrectamente el usuario no recibirá la oferta, todas las promociones tienen términos y condiciones y no se pueden acumular o tener más de una promoción u oferta activa. Es importante saber que primero se juega el Dinero Real y después el monto de Bono, para poder retirar las ganancias de Bono deberá consumirla toda o en parte dentro de la fecha de vencimiento especificada en las condiciones de cada bono. Si no comienza a usarla en ese plazo, se le retirará de su cuenta y ya no podrá utilizar su promoción.
            </p>
            <p className='style-text'>
              36. Al hacer uso de nuestro Sitio, usted puede incurrir en cargos por datos. Usted acepta que el visitar el Sitio fuera de una red Wifi puede resultar en cargos de datos de su proveedor de red móvil. No seremos responsables de ningún cargo por datos incurridos como resultado de su uso de la aplicación y/o el Sitio.
            </p>
            <p className='style-text'>
              37. Usted reconoce y se compromete a Jugar de manera responsable, esto significa que si está consciente que participar en los Juegos debe limitarse a utilizar el Sitio y todos sus servicios. Si se autoexcluye, no podrá acceder a nuestros servicios durante ese tiempo. Usted reconoce y acepta que, si decide autoexcluirse del Sitio, no se le permitirá abrir o utilizar una nueva cuenta de ninguna otra manera durante su período de autoexclusión seleccionado, hasta que dicha autoexclusión haya sido levantada y reabra la cuenta original. En el caso de que no cumpla con lo anterior, tendremos derecho a bloquear cualquier cuenta nueva que abra con otro Sitio, perderá los fondos que haya en él y no seremos responsables de reembolsarle los fondos que haya apostado o ganado a través de tal cuenta. Para obtener más información sobre la autoexclusión consulte nuestra política de Juego Responsable.
            </p>
            <p className='style-text'>
              38. No le otorgamos ningún derecho sobre nuestra propiedad intelectual. Somos el propietario o usuario autorizado o licenciatario de los derechos de propiedad intelectual sobre nuestros servicios y contenido, incluidos cualquier derecho de autor, patente, diseños (registrados o no registrados), marcas comerciales, marcas de servicio, códigos fuente, especificaciones, plantillas, gráficos, logotipos o cualquier derecho que subsista en cualquier otro lugar respecto al contenido, bases de datos, formatos, interfaces, programación, software o aplicación para cualquiera de los anteriores y modificaciones, mejoras de los mismos. Excepto en los casos en que le informamos específicamente, no le otorgamos ningún derecho expreso o implícito sobre ninguno de sus derechos de propiedad intelectual o información secreta. Usted no puede distribuir nuestro contenido a terceros. Cualquier contenido al que acceda a través de la aplicación o del Sitio es para su uso personal (es decir, no para uso comercial) y cualquier uso, distribución o reproducción de este contenido que se realice de cualquier manera sin nuestro consentimiento expreso por escrito está estrictamente prohibido. El uso de los datos en nuestra aplicación o del Sitio es una licencia personal. Esto se proporciona estrictamente a usted con un propósito personal, no exclusivo, no sublicenseable, no comercial para hacer uso de los servicios. Nos reservamos el derecho de tomar cualquier acción que consideremos necesaria, que puede incluir la emisión de procedimientos legales en su contra, con el fin de proteger nuestra propiedad intelectual y evitar el uso no autorizado de nuestros datos, aplicaciones, Sitio o servicios.
            </p>
            <p className='style-text'>
              39. Con relación al numeral 6, al crear una cuenta de usuario, usted no deberá seleccionar o usar la dirección de correo electrónico o el nombre de usuario de otra persona para hacerse pasar por ella; (c) usar un nombre ligado a los derechos de cualquier otra persona sin autorización; (c) usar una dirección de correo electrónico o un nombre de usuario profano, ofensivo o inapropiado; o (d) permitir que cualquier otra parte use su cuenta y/o contraseña.
            </p>
            <p className='style-text'>
              No puede compartir ni transferir ninguna cuenta. No puede revelar su contraseña a nadie. Usted acepta notificar inmediatamente a nosotros, enviando un correo electrónico misgallos.mx@gmail.com de cualquier uso no autorizado conocido o sospechado de su cuenta o de cualquier violación de seguridad conocida o sospechada, incluida la pérdida, el robo o la divulgación no autorizada de su contraseña. Usted comprende y acepta que será responsable de todas las actividades que ocurran en su cuenta, incluso si no las realizó usted. Nosotros no seremos responsables de ninguna pérdida o daño que surja de su falta de mantener la confidencialidad de su contraseña.
            </p>
            <p className='style-text'>
              40. Utilizaremos la información personal que nos proporcione:
            </p>
            <p className='style-text'>
              (a) para proveerle nuestros servicios;
            </p>
            <p className='style-text'>
              (b) para procesar su pago por los productos; y
            </p>
            <p className='style-text'>
              (c) si manifestó su acuerdo con esto durante el proceso de registro, para brindarle información sobre productos similares que le proporcionamos, pero puede dejar de recibir esto en cualquier momento al comunicarse con nosotros y, a nuestra entera discreción, podemos dejar de enviarle dichos correos electrónicos. en cualquier momento.
            </p>
            <p className='style-text'>
              Para obtener información detallada sobre cómo utilizaremos su información personal, consulte nuestra Política de Privacidad.
            </p>
            <p className='style-text'>
              41. Con relación al numeral 7, Nosotros nos reservamos el derecho a suspender su cuenta de usuario o cerrarla permanentemente cuando se tenga la sospecha de que usted se ha conducido en contravención de estos Términos y Condiciones; ha sido partícipe o ha estado involucrado en actividades fraudulentas o ilegales, incluyendo, pero sin limitarse a, cualquier violación a estos Términos y Condiciones y/o la legislación o regulaciones aplicables. EN ESTOS CASOS, EN NINGUNA CIRCUNSTANCIA, SE LE HARÁ ENTREGA O REEMBOLSO DE LAS CANTIDADES QUE USTED HAYA ENTREGADO A NOSOTROS PARA EL USO DE LOS SERVICIOS.
            </p>
            <p className='style-text'>
              42. La aceptación por parte del Usuario de estos Términos y Condiciones no constituirá de ninguna manera y en ninguna circunstancia una asociación, sociedad, relación laboral o de negocios entre el usuario y Nosotros.
            </p>
            <p className='style-text'>
              43. Podemos transferir nuestros derechos y obligaciones bajo estos términos a otra persona física o moral. Siempre le informaremos por escrito si esto sucede y nos aseguraremos de que la transferencia no afecte sus derechos en virtud del contrato.
            </p>
            <p className='style-text'>
              44. Estos Términos y Condiciones representan el acuerdo final y exclusivo entre usted y nosotros; al tiempo que reemplazan y dejan sin efecto cualquier arreglo, acuerdo, comunicado o representación (oral o escrita) previa. Cualquier cambio a los mismos será notificado a Usted conforme al procedimiento descrito en estos Términos y Condiciones.
            </p>
            <p className='style-text'>
              45. Los Términos y Condiciones se rigen por las leyes mexicanas. Cualquier procedimiento legal con respecto a los productos o servicios ofrecidos por nosotros, será ventilado ante los tribunales competentes en la Ciudad de México.
            </p>
            <p className='style-text'>
              46. Estos Términos y Condiciones establecen que los depósitos hechos con moneda nacional deben aplicarse en cualquier modalidad de juego disponible. Por lo que, en dado caso de que no sean aplicados en su totalidad, estos tendrán que cubrir todos los gastos operativos y los impuestos federales, como estatales, que se generen de esta transacción y a los que a estos apliquen.
            </p>
            <p className='style-text'>
              47.EN CUANTO LOS EVENTOS DE STREAMING EN VIVO.Nosotros no seremos responsables por alguna falla técnica o humana,. Si por ejemplo. Durante la transmisión ocurre una falla en la cual no permita que el usuario continúe viendo el evento. No seremos responsables de algún tipo de pago o indemnización monetaria.
            </p>
            <p className='style-text'>
              Nosotros sólo publicaremos en nuestra pagina y sitios ofíciales el resultado del evento.
            </p>
            <p className='style-text'>
              Si ocurriera algún error humano. Del juez o jueces, de los organizadores o personal. Por ejemplo algún cambio de color alguna decisión de fallo así como cualquier tipo de decisión.
            </p>
            <p className='style-text'>
              Nosotros no seremos responsables. SI OCURRIERA ALGUNA FALLA, nosotros cuando se restablezca la normalidad repartiremos las ganancias conforme al resultado del evento.
            </p>
            <p className='style-text'>
              En casos de fuerza mayor, seguridad, contingencia, errores o decisión de la empresa, nosotros podremos decretar el evento como empate y cancelar cualquier tipo apuesta.
            </p>
          </div>
        </div>
        <div className='container-image'>
          <img src={LogoCompleto} alt={"logoGALLOMX"} style={{ marginTop: "1em" }}></img>
        </div>
      </Container>
    </div>
  )
}

export default TerminosCondiciones;
