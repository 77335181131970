import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { Modal } from 'antd';

import { useUserAuth } from "../context/UserAuthContext";
import { Button } from "./ui";
import Logo from "../Images/xtremo-logo 1.png";
import './Login.css';

const CssTextFiel = styled(TextField)({
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
    '&:hover fieldset': {
      borderColor: '#ffffff', // Cambia el color del borde en hover
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ffffff',
  },
  '& .MuiInputLabel-root': {
    color: '#ffffff',
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
    },
  },
});

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailReset, setEmailReset] = useState("");
  const [error, setError] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [password, setPassword] = useState("");
  const [btnAceptar, setbtnAceptar] = useState(false);
  const [btnCerrar, setbtnCerrar] = useState(false);
  const { logIn, forgotPass } = useUserAuth();
  const [modalForgotPass, setModalForgotPass] = useState(false);
  const navigate = useNavigate();

  const handleChanged = async (e) => {
    setModalForgotPass(true)
    setbtnAceptar(true)
  }

  const handleClosed = async (e) => {
    setModalForgotPass(false)
    setbtnAceptar(true)
    setbtnCerrar(false)
    setErrorPass("")
    setSuccessMessage("")
    setEmailReset("")
  }

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!validateEmail(email)) {
      setError("Ingresa un correo electrónico valido");
      return;
    }
    if (password === "") {
      setError("Ingresa una contraseña");
      return;
    }
    try {
      await logIn(email.trim().toLowerCase(), password);
      navigate("/homeuser");
    } catch (err) {
      console.log('err', err);
      switch (err.code) {
        case "auth/invalid-email":
          setError("¡Comprueba tu correo electrónico!");
          break;
        case "auth/user-not-found":
          setError("¡Usuario no encontrado, revisa tu correo!");
          break;
        case "auth/wrong-password":
          setError("¡Revisa que tu contraseña este bien escrita!");
          break;
        default:
          //En caso de error inesperado se recarga la ventana
          //window.location.reload(false);
          setError("Error inesperado");
      }
    }
    //
  };

  const resetPassword = async (e) => {
    if (emailReset === "") {
      setErrorPass("Ingresa tu correo electrónico");
      return;
    }
    try {
      await forgotPass(emailReset);
      setSuccessMessage("Correo enviado correctamente")
      setErrorPass("")
      setbtnCerrar(true)
      setbtnAceptar(false)
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-email":
          setErrorPass("¡Comprueba tu correo electrónico!");
          break;
        case "auth/user-not-found":
          setErrorPass("¡Usuario no encontrado, revisa tu correo!");
          break;
        case "auth/wrong-password":
          setErrorPass("¡Revisa que tu contraseña este bien escrita!");
          break;
        default:
          setErrorPass("Error inesperado");
      }
    }
  };

  return (
    <div className="container-login">
      {/* Modal Restore Password */}
      <Modal
        open={modalForgotPass}
        closable={true}
        onOk={handleClosed}
        onCancel={handleClosed}
        footer={null}
      >
        <div>
          <Typography component="h1" variant="h5" color={'#fff'} align="center" >
            Ingresa tu correo electrónico
          </Typography>
          <br></br>
          <CssTextFiel
            margin="normal"
            required
            fullWidth
            id="formEmail"
            label="Correo electrónico"
            name="email"
            type="email"
            onChange={(e) => setEmailReset(e.target.value)}
            value={emailReset}
          />
          {errorPass && <Alert severity="error" style={{width: '100%'}}>{errorPass}</Alert>}
          {successMessage && <Alert severity="success" style={{width: '100%'}}>{successMessage}</Alert>}
          <Typography variant="body2" align="center" color={'#fff'}>
            Enviaremos un correo electrónico con un link para restablecer tu contraseña. En caso de no recibirlo, favor de revisar la bandeja de spam.
          </Typography>
          {btnAceptar &&
            <Button style={{ marginTop: '15px', height: '45px', fontSize: '18px' }} onClick={resetPassword}>
              ACEPTAR
            </Button>
          }
          {btnCerrar &&
            <Button
              style={{
                borderRadius: 35,
                backgroundColor: "rgb(120, 17, 19)",
                padding: "10px 36px",
                fontSize: "14px"
              }}
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 4, mb: 4 }}
              onClick={handleClosed}
            >
              Cerrar
            </Button>
          }
        </div>
      </Modal>

      <div className="container-login-image">
        <img src={Logo} alt="login" />
      </div>
      <div className="container-login-form">
        <div className="container-login-items">
        <Typography component="h1" variant="h4" color={'#FFFFFF'} sx={{ mt: 0, mb: 0 }} style={{ fontStyle: "italic", fontWeight: "bolder" }}>
          Iniciar sesión
        </Typography>
        <form onSubmit={handleSubmit}>
          <CssTextFiel
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <CssTextFiel
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Alert severity="error" style={{width: '100%'}}>{error}</Alert>}
          <Button type="submit" style={{ marginTop: '15px', height: '45px', fontSize: '18px' }}>
            INICIAR SESIÓN
          </Button>
        </form>
    
        <Button
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            height: '45px',
            fontSize: '17px',
            color: '#fff',
            marginBottom: '25px'
          }}
          onClick={handleChanged}
        >
          ¿Olvidaste la contraseña?
        </Button>
        <Typography style={{ textAlign: "center", color: "#ffffff" }}>
          ¿No estás registrado?
        </Typography>
        <Button
          variant="outlined"
          style={{ marginTop: '15px', height: '45px', fontSize: '18px' }}
          onClick={(e) => navigate("/signup")}
        >
          REGISTRATE
        </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
