import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  width: 100%;
  padding: 7px 15px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.3s;
  border: 1px solid #38A169;

  ${(props) =>
    props.variant === 'fill' &&
    css`
      background-color: #38A169;
      color: white;

      &:disabled {
        background-color: #444444;
        color: #A0A0A0;
        border-color: #A0A0A0;
        cursor: not-allowed;
      }
    `}

  ${(props) =>
    props.variant === 'outlined' &&
    css`
      background-color: transparent;
      color: #38A169;

      &:disabled {
        background-color: #444444;
        color: #A0A0A0;
        border-color: #A0A0A0;
        cursor: not-allowed;
      }
    `}
`;

const Button = ({ onClick, type = 'button', disabled = false, variant = 'fill', children, style }) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      style={style}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
