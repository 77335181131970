import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const Loading = () => {
  return (
    <Container component="main" maxWidth="xs" >
        <Box
            sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
        >
            <CircularProgress color="inherit" style={{color:"#38A169"}} />
        </Box>
    </Container>
  );
};
export default Loading;
