import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, doc, onSnapshot, limit, getDocs, orderBy } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import { styled } from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import ChatIcon from '@mui/icons-material/Chat';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { getAuth, getIdToken } from "firebase/auth";
import { notification, Alert, Spin, Modal } from 'antd';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ButtonMenu, Button as BtnC, CardMakeBet } from '../../components/ui';
import { ChatEvent } from "../../components/event/index";
import DraggableMenu from '../../components/ui/DraggableMenu';
import { firestore } from "../../firebase";
import { useUserAuth } from "../../context/UserAuthContext";
import '../fights/FightMobile.css';

const ModalTitle = styled.h3`
  color: #fff;
`;

const Label = styled.p`
  color: #fff;
  margin: 0;
`;

const BtnRed = styled.button`
  background-color: transparent;
  color: #E53E3E;
  border: 1px solid #E53E3E;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;

  &[data-selected="true"] {
    background-color: #E53E3E;
    color: #fff;
  }
`;

const BtnGreen = styled.button`
  background-color: transparent;
  color: #38A169;  
  border: 1px solid #38A169;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;

  &[data-selected="true"] {
    background-color: #38A169;
    color: #fff;
  }
`;

const TextInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #1C1C1C;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  max-width: 250px;
`;

const TableRowStyled = styled(TableRow)({
  '& .MuiTableCell-root': {
      borderBottom: '1px solid #1F1F1F',
      background: "#1A1A1A",
  },
});

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#EA9F26',
  },
});

const EventMobile = () => {
  const [event, setEvent] = useState(null);
  const [userBalance, setUserBalance] = useState(0);
  const [activeLive, setActiveLive] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [totalBet, setTotalBet] = useState(0);
  const [bets, setBets] = useState([]);
  const [userBets, setUserBets] = useState([]);
  const [totalGreen, setTotalGreen] = useState(0);
  const [totalRed, setTotalRed] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [openOptions, setOpenOptions] = useState(true);
  const [openChat, setOpenChat] = useState(false);
  const [showCreateBet, setShowCreateBet] = useState(false);
  const [showMakeBetError, setShowMakeBetError] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [betToHunt, setBetToHunt] = useState(null);
  const [selectedBet, setSelectedBet] = useState(null);
  const [showModalHunt, setShowModalHunt] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [editedBet, setEditedBet] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showEditBetError, setShowEditBetError] = useState(false);
  const [dateBets, setDateBets] = useState(false);
  const [nextFightLive, setNextFightLive] = useState("");
  const cardMakeBetRef = React.createRef();
  const [countDown, setCountDown] = useState(10);
  const [cancelRedirect, setCancelRedirect] = useState(false);
  const timerId = useRef();
  const [open, setOpenNext] = useState(false);

  const [team1, setTeam1] = useState('');
  const [team2, setTeam2] = useState('');
  const [eventStatus, setEventStatus] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [noCredit, setNoCredit] = useState(false);


  const handleOpen = () => setOpenNext(true);
  const handleClose = () => setOpenNext(false);

  const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');

  const navigate = useNavigate();
  const { user } = useUserAuth();
  const paramsIdEvent = useParams();

  const getUserData = async () => {
    const unsubscribe = onSnapshot(doc(firestore, "Users", user.uid), (doc) => {
      if(doc.data().dateBalance){
        const currentDate = new Date()
        const date = doc.data().dateBalance.toDate()
        const dif = currentDate.getTime() - date.getTime()
        const days = dif / (1000 * 60 * 60 * 24)
        if (days >= 1) {
          setNoCredit(true)
        }
      }
      setUserBalance(doc.data().balance);
      setActiveLive(doc.data().activeLive);
    });
    return () => unsubscribe();
  }

  const getEventData = async () => {
    const unsubscribe = onSnapshot(doc(firestore, "Events", paramsIdEvent.idEvent), (doc) => {
      setEvent(doc.data());
      setTeam1(doc.data().teams[0].name);
      setTeam2(doc.data().teams[1].name);
      setEventStatus(doc.data().status);
      if(doc.data().chat !== null) {
        setChatActive(doc.data().chat);
      } else {
        setChatActive(true);
      }
    });
    return () => unsubscribe();
  }

  const getBets = async () => {
    const betsCollectionRef = collection(firestore, "EventsBets");
    const q = query(betsCollectionRef, where("uidEvent", "==", paramsIdEvent.idEvent));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bet = [];
      const betsV = [];
      const betsR = [];
      querySnapshot.forEach((doc) => {
        bet.push({ ...doc.data(), id: doc.id });
        if (doc.data().idTeam === "1") {
          betsV.push({ ...doc.data(), id: doc.id });
        }
        if (doc.data().idTeam === "2") {
          betsR.push({ ...doc.data(), id: doc.id });
        }
      });
      setBets(bet);
      if (bet.length !== 0) {
        setTotalGreen((betsV.reduce((a, v) => a = a + v.bet, 0)))
        setTotalRed((betsR.reduce((a, v) => a = a + v.bet, 0)))
      }

    });
    return () => unsubscribe();
  }

  const getUserBets = async () => {
    const betsCollectionRef = collection(firestore, "EventsBets");
    const q = query(betsCollectionRef, where("uidFight", "==", paramsIdEvent.idEvent), where("uidUser", "==", user.uid));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bet = [];
      let totalUserBets = 0;
      querySnapshot.forEach((doc) => {
        bet.push({ ...doc.data(), id: doc.id });
        totalUserBets += doc.data().bet;
      });
      setUserBets(bet);
      setTotalBet(totalUserBets);
    });
    return () => unsubscribe();
  }

  const validateUserBets = async () => {
    const betsCollectionRef = collection(firestore, "Bets");
    const queryBets = query(betsCollectionRef, where("uidUser", "==", user.uid), orderBy("date", "desc"), limit(1));
    const snapshot = await getDocs(queryBets);
    const bets = [];
    snapshot.forEach((doc) => {
      bets.push({ ...doc.data(), id: doc.id, date: doc.data().date.toDate() });
    });
    const betsEventCollectionRef = collection(firestore, "EventsBets");
    const queryEventBets = query(betsEventCollectionRef, where("uidUser", "==", user.uid), orderBy("date", "desc"), limit(1));
    const snapshotEvent = await getDocs(queryEventBets);
    snapshotEvent.forEach((doc) => {
      bets.push({ ...doc.data(), id: doc.id, date: doc.data().date.toDate() });
    });

    const mostRecentBet = bets.reduce((latest, bet) => {
      const latestDate = new Date(latest.date);
      const currentDate = new Date(bet.date);
      return latestDate > currentDate ? latest : bet;
    });

    const currentDate = new Date()
    const date = mostRecentBet.date;
    const dif = currentDate.getTime() - date.getTime()
    const days = dif / (1000 * 60 * 60 * 24)

    if (days >= 21) {
      setDateBets(true)
    } else {
      setDateBets(false)
    }
  }

  const handleCloseMenu = () => {
    setOpen(false);
  }

  const handleOpenOptions = (option) => {
    setOpenOptions(!openOptions);
    if (option === 'chat') {
      setOpenChat(true);
    }
  };

  const handleBackMenu = () => {
    setOpenChat(false);
    setOpenOptions(true);
  }

  useEffect(() => {
    getBets();
    getUserBets();
    getEventData();
    getUserData();
    validateUserBets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMakeBet = async (amount, team) => {
    const teamId = team === team1 ? "1" : "2";
    setShowMakeBetError(false);
    if (amount < 50) {
      setShowMakeBetError(true);
      setMessage("La apuesta debe ser mayor o igual a 50");
      return;
    }
    if (userBalance < amount) {
      setShowMakeBetError(true);
      setMessage("Saldo insuficiente");
      return;
    }
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser);
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/event-create-bet`, {
        method: "POST",
        body: JSON.stringify({ eventId: paramsIdEvent.idEvent, teamId, bet: amount }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        setShowMakeBetError(true);
        setMessage(data.message);
        setLoading(false);
        return;
      }
      if (cardMakeBetRef.current) {
        cardMakeBetRef.current.reset();
      }
      notification.success({
        message: "Apuesta realizada",
        placement: "bottomRight"
      });
      setShowCreateBet(false);
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setShowMakeBetError(true);
      setMessage(error.message);
      setLoading(false);
    }
  }

  const handleHuntBet = async (values) => {
    if (userBalance < values.bet) {
      notification.error({
        message: "Saldo insuficiente",
        description: "Tu saldo es insuficiente para realizar esta apuesta",
        placement: "bottomRight"
      });
      return;
    }
    const newBet = {
      ...values,
      idTeam: values.idTeam === "1" ? "2" : "1",
      teamName: values.idTeam === "1" ? team2 : team1,
    };
    setSelectedBet(newBet);
    setBetToHunt(values);
    setShowModalHunt(true);
  };

  const handleEditBet = async () => {
    if (selectedBet.bet === editedBet && selectedBet.idTeam === selectedTeam) {
      setShowEditBetError(true);
      setMessage("No se han realizado cambios");
      return;
    }
    if (userBalance < editedBet) {
      setShowEditBetError(true);
      setMessage("Saldo insuficiente");
      return;
    }
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/event-edit-bet`, {
        method: "POST",
        body: JSON.stringify({ betId: selectedBet.id, teamId: selectedTeam, bet: editedBet }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        setShowEditBetError(true);
        setMessage(data.message);
        setLoading(false);
        return;
      }
      handleCloseEdit();
      setLoading(false);
      notification.success({
        message: "Apuesta editada",
        placement: "bottomRight"
      });
    } catch (error) {
      console.error("Error: ", error)
      setShowEditBetError(true);
      setMessage(error?.message);
      setLoading(false);
    }
  };

  const huntBet = async () => {
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/event-hunt-bet`, {
        method: "POST",
        body: JSON.stringify({ betId: betToHunt.id, teamId: betToHunt.idTeam, bet: betToHunt.bet }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        notification.error({
          message: "Error",
          description: data.message,
          placement: "bottomRight"
        });
        setLoading(false);
        return;
      }
      notification.success({
        message: "Apuesta cazada",
        placement: "bottomRight"
      });
      handleCloseHunt();
      setLoading(false);
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: error?.message,
        placement: "bottomRight"
      });
      setLoading(false);
    }
  };

  const searchEventLive = async () => {
    const ref = collection(firestore, "Events");
    const q = query(ref, where("live", "==", true), limit(1));

    const item = [];
    let isFightActive = false;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      item.push({ ...doc.data(), id: doc.id });
    });
    if (item.length === 0) {
      return;
    }
    item.forEach((data, index) => {
      if (data.id !== paramsIdEvent.idEvent && data.status === true) {
        setNextFightLive(data.id);
        isFightActive = true;
      }
    });

    if (isFightActive) {
      handleOpen();
    }
  }

  const handleCancelRedirect = () => {
    setCancelRedirect(true);
    handleClose();
    setNextFightLive(paramsIdEvent.idEvent);
  }

  useEffect(() => {
    if (open) {
      timerId.current = setInterval(() => {
        setCountDown(prev => prev - 1);
      }, 1000)
      return () => clearInterval(timerId.current);
    }
  }, [open]);

  useEffect(() => {
    if (countDown <= 0 && !cancelRedirect) {
      clearInterval(timerId.current);
      navigate("/event-view/" + nextFightLive);
      window.location.reload(false);
    }
    if (countDown === -2) {
      navigate("/event-view/" + nextFightLive);
      window.location.reload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  useEffect(() => {
    if (!eventStatus && eventStatus !== null) {
      searchEventLive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventStatus]);

  const handleEdit = (bet) => {
    setSelectedBet(bet);
    setEditedBet(bet.bet);
    setShowModal(true);
  };

  const handleCloseEdit = () => {
    setShowModal(false);
    setSelectedBet(null);
    setEditedBet(null);
    setSelectedTeam(null);
    setShowEditBetError(false);
  };

  const handleCloseHunt = () => {
    setShowModalHunt(false);
    setSelectedBet(null);
    setBetToHunt(null);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
  
    setVh();
    window.addEventListener('resize', setVh);
  
    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%', height: 'calc(var(--vh, 1vh) * 100)', overflow: 'hidden' }}>
      <div className={`iframe-container ${orientation === 'landscape' ? 'iframe-landscape' : 'iframe-portrait'}`}>
        
      </div>
      {event?.live && activeLive === true && dateBets === false ? (
        <div className={`iframe-container ${orientation === 'landscape' ? 'iframe-landscape' : 'iframe-portrait'}`}>
          <iframe
            id={event?.idVideo}
            src={event?.urlVideo}
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="no"
            allow="autoplay;"
            allowFullScreen={false}
            webkitallowfullscreen={false}
            mozallowfullscreen={false}
            oallowfullscreen={false}
            msallowfullscreen={false}
            playInFullScreen={false}
            autoPlay={true}
            style={{ position: 'absolute', top: '0', left: '0' }}
            title='Live'
            playsinline
            webkit-playsinline={0}
          ></iframe>
        </div>
      ) : 
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography component="h1" variant="h5" align="center" style={{ fontSize: "20px", color: 'white', fontWeight: "normal" }}>
            Transmisión apagada
          </Typography>
          {dateBets &&
            <h3 style={{ margin: 0, color: '#fff'}}>
              Tienes varios días sin apostar, es necesario realizar por lo menos una apuesta para poder seguir disfrutando de las transmisiones en vivo en XTREMO
            </h3>
          }
          {noCredit &&
            <Typography component="h1" variant="h5" align="center" style={{ fontSize: "20px", color: '#FFFFFF', fontWeight: "bold", marginBottom: "1em" }}>
              Han pasado las 24 horas de acceso gratuito a las transmisiones en vivo. Para seguir disfrutando de nuestro streaming, debes tener al menos $100.00 en tu cuenta.
            </Typography>
          }
        </div>
      }
      <div style={{ position: 'absolute', left: '30%', top: '5px', zIndex: 1 }}>
        <span style={{ color: '#fff'}}><b style={{ color: '#33cc33' }}>${totalGreen}</b></span>
      </div>
      <div style={{ position: 'absolute', right: '30%', top: '5px', zIndex: 1 }}>
        <span style={{ color: '#fff'}}><b style={{ color: '#ff0000' }}>${totalRed}</b></span>
      </div>
      <div style={{ position: 'absolute', bottom: '20px', left: '5px', zIndex: 1 }}>
        <span style={{ color: '#fff'}}>Apostado <b style={{ color: '#ffcc66' }}>${totalBet}</b></span>
      </div>
      <div style={{ position: 'absolute', bottom: '5px', left: '5px', zIndex: 1 }}>
        <span style={{ color: '#fff'}}>Saldo <b style={{ color: '#ffcc66' }}>${userBalance}</b></span>
      </div>
      <div style={{ position: 'absolute', bottom: '5px', right: '5px', zIndex: 1 }}>
        <span style={{ color: '#fff'}}><b style={{ color: '#ffcc66' }}>{event?.eventName}</b></span>
      </div>
      <button
        id="basic-button"
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        className="menu-icon-button"
        onClick={() => setOpen(true)}
      >
        <MenuIcon style={{ color: "#fff" }} />
      </button>
      {event?.betStatus &&
        <BtnC
          style={{
            position: 'absolute',
            top: '10px',
            left: '5px',
            zIndex: 1,
            width: 'auto',
            padding: 5
          }}
          onClick={() => setShowCreateBet(!showCreateBet)}
        >
          {showCreateBet ? 'Cancelar' : 'Apostar'}
        </BtnC>
      }
      {showCreateBet && (
        <div style={{ position: 'absolute', top: '54%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CardMakeBet
            title=''
            amounts={[100, 200, 500, userBalance]}
            team1={team1}
            team2={team2}
            handleMakeBet={handleMakeBet}
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.4)' }}
          >
            {showMakeBetError && (
              <Alert
                message={message}
                type="error"
                showIcon
              />
            )}
          </CardMakeBet>
        </div>
      )}

      {!eventStatus && (
        <div style={{ position: 'absolute', left: '50%', top: '40%', zIndex: 1 }}>
          <Typography
            component="h1"
            variant="h5"
            style={{ textAlign: "center", fontSize: "18px", color: '#ffffff' }}
          >
            ¡El evento ha terminado!
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            sx={{ mt: 0.5, pb: 0.5 }}
            style={{ textAlign: "center", fontSize: "18px", color: '#ffffff' }}>
            El lado ganador es
          </Typography>
          {event?.winner === "1" &&
            <Box align="center" sx={{ mt: 1 }}
              style={{
                backgroundColor: "#50AF48",
                borderRadius: 6,
                padding: "12px 20px",
                fontSize: "12px"
              }}>
              <Typography component="h1" variant="h5" sx={{ mt: 0.5 }}
                style={{ textAlign: "center", fontSize: "18px", color: 'white' }}>
                {team1}
              </Typography>
            </Box>
          }
          {event?.winner === "2" &&
            <Box align="center" sx={{ mt: 1 }}
              style={{
                backgroundColor: "#D32F2F",
                borderRadius: 6,
                padding: "12px 20px",
                fontSize: "12px"
              }}>
              <Typography component="h1" variant="h5" sx={{ mt: 0.5 }}
                style={{ textAlign: "center", fontSize: "18px", color: 'white' }}>
                {team2}
              </Typography>
            </Box>
          }
          {event?.winner === "Empate" &&
            <Box align="center" sx={{ mt: 1 }}
              style={{
                backgroundColor: "#1D1D1B",
                borderRadius: 6,
                padding: "12px 20px",
                fontSize: "12px"
              }}>
              <Typography component="h1" variant="h5" sx={{ mt: 0.5 }}
                style={{ textAlign: "center", fontSize: "18px", color: 'white' }}>
                {event?.winner}
              </Typography>
            </Box>
          }
        </div>
      )}

      {isOpen && (
        <DraggableMenu isOpen={isOpen} handleClose={handleCloseMenu} showBackArrow={!openOptions} onClickBack={handleBackMenu}>
          {openOptions && (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '10px', maxHeight: '100%', overflow: 'auto' }}>
              <div className='container-menu-buttons'>
                <ButtonMenu title={'Chat'} icon={<ChatIcon style={{ color: "#fff" }} />} onClick={() => handleOpenOptions('chat')} />
                <ButtonMenu title={'Agregar fondos'} icon={<AttachMoneyIcon style={{ color: "#fff" }} />} onClick={() => navigate('/add-money')} />
              </div>
              <div style={{ display: 'flex', flex: 5, flexDirection: 'column', paddingLeft: '10px', paddingRight: '10px', overflow: 'auto' }}>
                <StyledTabs
                  value={tabValue}
                  onChange={handleChangeTab}
                  indicatorColor=""
                  textColor="inherit"
                  sx={{ width: "100%" }}>
                  <Tab label="Todas las apuestas" sx={{ width: "50%" }} style={{ back: "green", color: "#ffffff" }} />
                  <Tab label="Mis Apuestas" sx={{ width: "50%" }} style={{ color: "#ffffff" }} />
                </StyledTabs>
                {tabValue === 0 && (
                  <TableContainer component={Paper} sx={{ mt: 1, backgroundColor: "#1A1A1A" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRowStyled>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 20, padding: '0px' }}>Equipo</TableCell>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 30, padding: '4px' }}>Apuesta</TableCell>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 45, padding: '4px' }}>Opciones</TableCell>
                        </TableRowStyled>
                      </TableHead>
                      <TableBody>
                        {bets.map((doc, index) => (
                          <TableRowStyled key={doc.id}>
                            {doc.idTeam === "1" && (
                              <>
                                <TableCell align="center" style={{ color: "#33cc33", border: 0, maxWidth: 20, padding: '5px', fontSize: "13px", }}>{doc.teamName}</TableCell>
                                <TableCell align="center" style={{ color: "#fff", border: 0, maxWidth: 30, padding: '5px', fontSize: "13px", }}>${doc.bet.toFixed(2)}</TableCell>
                                {!doc.isBetHunt && (doc.uidUser !== user.uid) && event?.betStatus ? (
                                  <TableCell align="center" style={{ color: "#33cc33", border: 0, maxWidth: 45, padding: '5px' }}>
                                    <BtnC
                                      style={{
                                        fontSize: "9px",
                                        width: "auto",
                                        height: "auto",
                                        padding: "4px 7px 4px 7px",
                                      }}
                                      onClick={() => handleHuntBet(doc)}
                                    >
                                      Cazar
                                    </BtnC>
                                  </TableCell>
                                ) :
                                  <TableCell align="center" style={{ color: "white", border: 0, maxWidth: 45, padding: '5px', fontSize: "12px" }}>
                                    {doc.uidUser === user.uid && "Tu apuesta"}
                                    {doc.uidBetHunt === user.uid && "Apuesta cazada"}
                                  </TableCell>
                                }
                              </>
                            )}

                            {doc.idTeam === "2" && (
                              <>
                                <TableCell align="center" style={{ color: "#ff0000", border: 0, maxWidth: 20, padding: '5px', fontSize: "13px" }}>{doc.teamName}</TableCell>
                                <TableCell align="center" style={{ color: "#fff", border: 0, maxWidth: 30, padding: '5px', fontSize: "13px" }}>${doc.bet.toFixed(2)}</TableCell>
                                {!doc.isBetHunt && (doc.uidUser !== user.uid) && event?.betStatus ? (
                                  <TableCell align="center" style={{ color: "#ff0000", border: 0, padding: '5px', maxWidth: 45, }}>
                                    <BtnC
                                      style={{
                                        fontSize: "9px",
                                        width: "auto",
                                        height: "auto",
                                        padding: "4px 7px 4px 7px",
                                      }}
                                      onClick={() => handleHuntBet(doc)}
                                    >
                                      Cazar
                                    </BtnC>
                                  </TableCell>
                                ) :
                                  <TableCell align="center" style={{ color: "white", border: 0, padding: '5px', fontSize: "12px", maxWidth: 45, }}>
                                    {doc.uidUser === user.uid && "Tu apuesta"}
                                    {doc.uidBetHunt === user.uid && "Apuesta cazada"}
                                  </TableCell>
                                }
                              </>
                            )}
                          </TableRowStyled>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {tabValue === 1 && (
                  <TableContainer
                    component={Paper}
                    sx={{ mt: 1, backgroundColor: "#1A1A1A" }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRowStyled>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", border: 0, color: "white", maxWidth: 25, padding: '2px' }}>Equipo</TableCell>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", border: 0, color: "white", maxWidth: 25, padding: '4px' }}>Apuesta</TableCell>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", border: 0, color: "white", maxWidth: 40, padding: '4px' }}>Estatus</TableCell>
                          {event?.betStatus &&
                            <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 30, padding: '4px' }}>Editar</TableCell>}
                        </TableRowStyled>
                      </TableHead>
                      <TableBody>
                        {userBets.map((doc, index) => (
                          <TableRowStyled key={doc.id}>
                            {doc.idTeam === "1" &&
                              <TableCell align="center" style={{ maxWidth: 25, border: 0, fontSize: "13px", color: "#33cc33", padding: '5px' }} >
                                {doc.teamName}
                              </TableCell>
                            }
                            {doc.idTeam === "2" &&
                              <TableCell align="center" style={{ maxWidth: 25, border: 0, fontSize: "13px", color: "#ff0000", padding: '5px' }} >
                                {doc.teamName}
                              </TableCell>
                            }
                            <TableCell align="center" style={{ maxWidth: 35, border: 0, fontSize: "12px", color: "white", padding: '5px' }}>${doc.bet.toFixed(2)}</TableCell>
                            <TableCell align="center" style={{ maxWidth: 40, border: 0, fontSize: "12px", color: "white", padding: '5px' }}>{doc.status}</TableCell>
                            {event?.betStatus &&
                              <TableCell align="center" style={{ maxWidth: 30, border: 0, paddingRight: 4, fontSize: "12px", padding: '5px' }}>
                                {!doc.isBetHunt && (
                                  <BtnC
                                    style={{
                                      color: "#FFFFFF",
                                      fontSize: "12px",
                                      width: "auto",
                                      padding: "2px 5px 2px 5px",
                                    }}
                                    onClick={(e) => handleEdit(doc)}>
                                    Editar
                                  </BtnC>
                                )}
                              </TableCell>}
                          </TableRowStyled>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                
              </div>
            </div>
          )}
          {!openOptions && (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '10px', maxHeight: '100%', overflow: 'auto' }}>
              {openChat && <ChatEvent active={chatActive} style={{ width: '100%', maxHeight: '100%', overflow: 'auto' }} />}
            </div>
          )}
        </DraggableMenu>
      )}

      {/* Modal Edit Bet */}
      <Modal
        closable={true}
        open={showModal}
        onCancel={handleCloseEdit}
        footer={null}
      >
        <div>
          <ModalTitle>Estas editando tu apuesta</ModalTitle>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Label>Equipo: <b style={{ color: selectedBet?.idTeam === "1" ? "#38A169" : "#E53E3E" }}>{selectedBet?.teamName}</b></Label>
              <Label>Apuesta: ${selectedBet?.bet}</Label>
            </div>
            <div style={{ display: 'flex', flex: 2, flexDirection: 'column', alignItems: 'center' }}>
              <Label>Elige un equipo</Label>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                <BtnGreen
                  onClick={() => setSelectedTeam("1")}
                  data-selected={selectedTeam === "1"}
                >
                  {team1}
                </BtnGreen>
                <BtnRed
                  onClick={() => setSelectedTeam("2")}
                  data-selected={selectedTeam === "2"}
                >
                  {team2}
                </BtnRed>
              </div>
              <TextInput
                type="number"
                min="50"
                value={editedBet}
                onChange={(e) => setEditedBet(Number(e.target.value))}
                placeholder="Otra cantidad..."
                style={{ marginBottom: '5px' }}
              />
              <BtnC
                onClick={handleEditBet}
                style={{ width: '180px', padding: '4px', marginBottom: '5px' }}
                disabled={!editedBet || !selectedTeam || editedBet < selectedBet?.bet}
              >
                Guardar
              </BtnC>
              {showEditBetError && (
                <Alert
                  message={message}
                  type="error"
                  showIcon
                />
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal Hunt Bet */}
      <Modal
        closable={true}
        open={showModalHunt}
        onCancel={handleCloseHunt}
        footer={null}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ModalTitle>Estas cazando una apuesta</ModalTitle>
          <p style={{ color: '#fff' }}>Equipo: <b style={{ color: selectedBet?.idTeam === "1" ? "#38A169" : "#E53E3E" }}>{selectedBet?.teamName}</b></p>
          <p style={{ color: '#fff' }}>Apuesta: ${selectedBet?.bet}</p>
          <BtnC onClick={huntBet} style={{ width: '180px', padding: '4px', marginBottom: '5px' }}>ACEPTAR</BtnC>
        </div>
      </Modal>

      {/* Modal Next Event*/}
      <Modal
        closable={true}
        open={open}
        onCancel={handleClose}
        footer={null}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ModalTitle>El evento ha terminado!</ModalTitle>
          <p style={{ color: '#fff' }}>No te preocupes, te llevaremos al siguiente evento automaticamente en: {countDown}</p>
          <BtnC onClick={handleCancelRedirect} style={{ width: '180px', padding: '4px', marginBottom: '5px' }}>QUEDARME AQUI</BtnC>
        </div>
      </Modal>

      {loading && (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1100,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            width: '100%',
            height: '600vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin
            style={{
              position: 'absolute', left: '50%', top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            size="large"
            spinning={loading}
          />
        </div>
      )}
    </div>
  );
};

export default EventMobile;
